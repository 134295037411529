import { UploadFileStatus } from "antd/lib/upload/interface";
import Axios, { CancelTokenSource } from "axios";
import { Moment } from "moment";
import qs from "qs";

export enum Errors {
  Unauthorized = "unauthorized",
  ServerError = "server error",
  ClientError = "client error",
  NotFound = "not found",
  BadRequest = "bad request",
  RequestCancelled = "request cancelled",
}

export interface APIError {
  status: "error";
  message: string;
  error: Errors;
}

export interface APISuccess<T> {
  status: "success";
  data: T;
}

export interface APIResponse<A> {
  success: boolean;
  message: string;
  data: A;
}

export type APIResult<T> = APISuccess<T> | APIError;

export interface AuthService {
  createFirebaseCustomToken(): Promise<APIResult<{ token: string }>>;
}

export interface ComfortRange {
  lower: number;
  target: number;
  upper: number;
}

export enum ContentType {
  JSON = "application/json",
  PDF = "application/pdf;charset=utf8",
  CSV = "text/csv",
  XLSX = "application/xlsx;charset=utf8",
  MULTIPART = "multipart/form-data",
}

export interface EnergyConsumption {
  energyMeterId: number;
  date: string;
  consumption: number;
  unitOfMeasure: UnitOfMeasure;
}

export type EnergyMeterType = "cpcu" | "grdf" | "distech" | "iq4";

export type GrdfDataType = "informative" | "published";

export enum EnergyMeterCategory {
  Reference = "reference",
  Aucune = "",
}

export enum EnergyMeterRole {
  Hdw = "hot_domestic_water",
  Heating = "heating",
  HeatingHdw = "heating_hdw",
}
export interface GRDFConsentDetails {
  pceId: string;
  postalCode: string;
  ownerEmail: string;
  socialReason?: string;
  ownerName?: string;
}

export type EnergyMeterMetadata = {
  plcId?: string;
  dataType?: GrdfDataType;
  pceId?: string;
  postalCode?: string;
  ownerEmail?: string;
  socialReason?: string;
  ownerName?: string;
};

export interface EnergyMeter {
  id: number;
  serialNumber: string;
  name: string;
  comments: string;
  siteId: number;
  unitOfMeasure: UnitOfMeasure;
  type: EnergyMeterType;
  metadata: EnergyMeterMetadata;
  category: EnergyMeterCategory;
  role: EnergyMeterRole;
}

export type EnergyMeterJobCategory = "plc_measurements" | "cpcu_measurements" | "grdf_measurements";

export interface EnergyMeterJobMonitoring {
  energyMeterId: number;
  jobCategory: EnergyMeterJobCategory;
  jobMonitoring: JobMonitoring;
}

export interface EnergyMeterJobsConfiguration {
  energyMeterId: number;
  jobCategory: EnergyMeterJobCategory;
  jobConfiguration: JobConfiguration;
}

export interface EnergyMeterWithJobConfiguration {
  energyMeterDetails: EnergyMeter;
  jobsConfigurations: EnergyMeterJobsConfiguration[];
  droitAccesGrdf: Record<string, string>;
}

export interface EnergyMeterService {
  fetchOne(params: { emId: string }): Promise<APIResult<EnergyMeter>>;

  fetchMany(params: { emIds: string[] }): Promise<APIResult<EnergyMeter[]>>;
}

export interface EnergyMeasurement {
  id: number;
  energyMeterId: number;
  timestamp: string;
  measurement: number;
}

export enum RegulationType {
  Eco = "eco",
  Comfort = "comfort",
  Custom = "custom",
  Ecco2 = "ecco2",
  None = "none",
}

export enum RegulationParameterLabel {
  TargetTemperature = "targetTemperature",
  NoHeatingTemperature = "noHeatingTemperature",
  HeatingTimeConstant = "heatingTimeConstant",
  CoolingTimeConstant = "coolingTimeConstant",
  HeatingLawSlope = "heatingLawSlope",
  TemperatureThreshold1 = "temperatureThreshold1",
  AlphaThreshold1 = "alphaThreshold1",
  TemperatureThreshold2 = "temperatureThreshold2",
  AlphaThreshold2 = "alphaThreshold2",
  SolarNormalisation = "solarNormalisation",
  SolatWeight = "solarWeight",
  SolarMaxTemperature = "solarMaxTemperature",
  SolarMeanTemperature = "solarMeanTemperature",
  OffsetUpperLimit = "offsetUpperLimit",
  OffsetLowerLimit = "offsetLowerLimit",
  RegulationType = "regulationType",
  CustomOffset = "customOffset",
}

export interface RegulationMeasurement {
  timestamp: string;
  predictorRegulation: {
    airOffset: number;
    solarOffset: number;
    slopeOffset: number;
    totalOffset: number;
    feedbackValue: number;
    feedbackValueFiltered: number;
  };
  predictorParameters: {
    solarParameters: {
      solarNormalisation: number;
      solarWeight: number;
      solarMaxTemperature: number;
      solarMeanTemperature: number;
    };
    slopeParameters: {
      temperatureThreshold1: number;
      alphaThreshold1: number;
      temperatureThreshold2: number;
      alphaThreshold2: number;
    };
    airParameters: {
      heatingTimeConstant: number;
      coolingTimeConstant: number;
    };
    targetTemperature: number;
    noHeatingTemperature: number;
    heatingLawSlope: number;
    offsetUpperLimit: number;
    offsetLowerLimit: number;
    regulationType: RegulationType;
    customOffset: number;
  };
  offsetEcco2: number;
}

export type SchedulingModeApi = "Auto" | "Confort" | "Préconfort" | "Economie" | "Hors gel";

export interface SchedulingMeasurement {
  hdcId: number;
  timestamp: string;
  ambiantTemperature: number;
  comfortTemperature: number;
  protectionTemperature: number;
  regulationMode: SchedulingModeApi;
  isActive: boolean;
}

export interface SchedulingParameters {
  id: string;
  hdcId: number;
  coolingInertia: number;
  heatingRate: number;
  degreeMargin: number;
  calendarAddress: string;
  isActive: boolean;
  preHeatingMinutes: number;
}

export interface SchedulingEvent {
  hdcId: number;
  isEvent: boolean;
  timestamp: string;
}

export interface SchedulingParametersInput {
  coolingInertia: number;
  heatingRate: number;
  preHeatingMinutes: number;
}

export interface SolarParameters {
  solarNormalisation: number;
  solarWeight: number;
  solarMaxTemperature: number;
  solarMeanTemperature: number;
}

export interface SlopeParameters {
  temperatureThreshold1: number;
  alphaThreshold1: number;
  temperatureThreshold2: number;
  alphaThreshold2: number;
}

export interface AirParameters {
  heatingTimeConstant: number;
  coolingTimeConstant: number;
}

export interface RegulationParameters {
  solarParameters: SolarParameters;
  slopeParameters: SlopeParameters;
  airParameters: AirParameters;
  targetTemperature: number;
  noHeatingTemperature: number;
  heatingLawSlope: number;
  offsetUpperLimit: number;
  offsetLowerLimit: number;
  regulationType: string;
  customOffset: number;
}

export interface DJUSaving {
  hdcId: number;
  timestamp: string;
  djuSaved: number;
  frequency: "hourly" | "daily";
}

export interface Coordinates {
  latitude: number;
  longitude: number;
  elevation?: number;
}

export interface EnergyMeasurementService {
  downloadConsumptions(params: {
    mId: string;
    from: number;
    to: number;
    sId: number;
    fileName: string;
  }): Promise<void>;

  fetchMeasurements(params: {
    emIds: string[];
    from: number;
    to: number;
  }): Promise<APIResult<EnergyMeasurement[]>>;

  fetchConsumptions(params: {
    emIds: string[];
    from: number;
    to: number;
  }): Promise<APIResult<EnergyConsumption[]>>;
}

export type HttpMethod = "GET" | "POST" | "PUT" | "DELETE";

export interface HeatingProductionUnitMetadatas {
  water_flow_set_point_temperature: string;
}

export interface HeatingProductionUnit {
  id: number;
  name: string;
  siteId: number;
  metadata: HeatingProductionUnitMetadatas;
}

export const productionDataPointCategory = [
  "water_flow_temperature",
  "water_flow_set_point_temperature",
  "water_return_temperature",
  "",
] as const;

export type ProductionDataPointCategory = (typeof productionDataPointCategory)[number];

export interface ProductionDataPoint {
  id: number;
  category: ProductionDataPointCategory;
  productionUnitId: number;
  plcId: number | null;
  plcAddress: string | null;
}

export const distributionDataPointCategory = [
  "water_flow_temperature",
  "offset_read",
  "offset_write",
  "water_flow_set_point_temperature",
  "three_way_valve_travel_percent",
  "water_flow_temperature_plus_offset_calculated",
  "water_flow_temperature_emulated",
  "water_flow_temperature_applied",
  "water_return_temperature",
  "composite_outside_temperature",
  "heating_curve_point_x1",
  "heating_curve_point_y1",
  "heating_curve_point_x2",
  "heating_curve_point_y2",
  "heating_curve_point_x3",
  "heating_curve_point_y3",
  "heating_curve_point_x4",
  "heating_curve_point_y4",
  "predictive_system_local_state_read",
  "predictive_system_remote_state_read_and_write",
  "water_flow_temperature_disconnected_sensor_alarm",
  "water_flow_temperature_short_circuit_sensor_alarm",
  "water_return_temperature_disconnected_sensor_alarm",
  "water_return_short_circuit_sensor_alarm",
  "heating_energy_comsumption",
  "water_flow_energy_comsumption",
  "plant_active_energy",
  "plant_reactive_energy",
  "v3v_regulation",
  "no_heating_temperature",
  "heating_curve_day_correction",
  "heating_curve_night_correction",
  "",
] as const;

export type DistributionDataPointCategory = (typeof distributionDataPointCategory)[number];

export type DataPointDataType = "float" | "int" | "bool";

export interface DistributionDataPoint {
  id: number;
  category: DistributionDataPointCategory;
  heatingDistributionCircuitId: number;
  plcId: number | null;
  plcAddress: string | null;
  dataType: DataPointDataType;
}

export type DataPoint = DistributionDataPoint | ProductionDataPoint;

export type PlcJobCategory = "health_check" | "watchdog_update";

interface PLCMetadata {
  watchdogCounterRead?: string;
  watchdogCounterWrite?: string;
  watchdogPresetRead?: string;
  watchdogStateRead?: string;
}

export interface PLC {
  id: number;
  name: string;
  siteId: number;
  supplierId: number;
  url: string;
  username: string;
  password: string;
  metadata: PLCMetadata;
  isMonitoringActive: boolean;
}

export interface JobMonitoring {
  lastExecutionStatus: ExecutionStatus;
  lastExecutionTime: number;
  errorMessage: string;
  failuresCount: number;
  lastStatusChange: number;
}

export interface PlcJobMonitoring {
  plcId: number;
  jobCategory: PlcJobCategory;
  jobMonitoring: JobMonitoring;
}

export interface PlcJobsConfiguration {
  plcId: number;
  jobCategory: PlcJobCategory;
  jobConfiguration: JobConfiguration;
}

export interface PlcWithJobConfiguration {
  plc_details: PLC;
  jobsConfigurations: PlcJobsConfiguration[];
  healthz: PlcHealthStatus;
}

export type JobCategory = HdcJobCategory | PlcJobCategory | EnergyMeterJobCategory;

export type ExecutionStatus = "success" | "failure";

export type AlertStatus = "active" | "standby";

export type CircuitConfiguration = "full" | "partial";

export type HdcJobCategory = "measurements" | "heating_curve" | "regulation";

export interface HeatingDistributionCircuitJobMonitoring {
  heatingDistributionCircuitId: number;
  jobCategory: HdcJobCategory;
  jobMonitoring: JobMonitoring;
}

export interface HdcJobMonitoring {
  heatingDistributionCircuitId: number;
  jobCategory: HdcJobCategory;
  jobMonitoring: JobMonitoring;
}

export interface HdcJobsConfiguration {
  heatingDistributionCircuitId: number;
  jobCategory: HdcJobCategory;
  jobConfiguration: JobConfiguration;
}

export interface HdcWithJobConfiguration {
  hdcDetails: HeatingDistributionCircuit;
  jobsConfigurations: HdcJobsConfiguration[];
}

export interface JobConfiguration {
  id?: number;
  isActive: boolean;
  alertStatus: AlertStatus;
  comments: string;
}

export type JobAlertCategories =
  | "heatingDistributionCircuits"
  | "plcs"
  | "heatingProductionUnits"
  | "energyMeters";

export type JobAlerts = {
  heatingDistributionCircuits: HeatingDistributionCircuitJobMonitoring[];
  plcs: PlcJobMonitoring[];
  heatingProductionUnits: HpuJobMonitoring[];
  energyMeters: EnergyMeterJobMonitoring[];
};

export type RequeaGateway = {
  name: string;
  connectionStatus: boolean;
  comment: string;
  lastConnection: string;
};

export interface HeatingProductionUnitMeasurement {
  id: number;
  heatingProductionUnitId: number;
  timestamp: string;
  waterFlowTemperature: number;
  waterFlowSetPointTemperature: number;
  waterReturnTemperature: number;
}

export interface PredictiveMonitoring {
  heatingDistributionCircuitName: string;
  siteName: string;
  controls: HeatingDistributionCircuitControls;
  heatingLawSlopeConfig: number;
  noHeatingTemperatureConfig: number;
  heatingLawSlopeMeasure: number;
  isNoHeatingTemperatureValid: boolean;
  noHeatingTemperatureMeasure: number;
  timestamp: string;
  hasRegulationError: boolean;
  hasMeasurementError: boolean;
  hasCurveMeasureError: boolean;
  isRegulated: boolean;
  offset: number;
}

export type HpuJobCategory = "measurements";

export interface HpuJobMonitoring {
  heatingProductionUnitId: number;
  jobCategory: HpuJobCategory;
  jobMonitoring: JobMonitoring;
}

export interface HpuJobsConfiguration {
  heatingProductionUnitId: number;
  jobCategory: HpuJobCategory;
  jobConfiguration: JobConfiguration;
}

export interface HpuWithJobConfiguration {
  hpuDetails: HeatingProductionUnit;
  jobsConfigurations: HpuJobsConfiguration[];
}

export type HeatingDistributionCircuitRole = "heating" | "domestic_hot_water";

export type HeatingCircuitMonitoringLevel = "active" | "partial" | "inactive";

export interface HeatingDistributionCircuitMetadatas {
  heating_curve_point_x1?: string;
  heating_curve_point_x2?: string;
  heating_curve_point_x3?: string;
  heating_curve_point_x4?: string;
  water_flow_set_point_temperature?: string;
  v3v_regulation?: string;
}

export interface HeatingDistributionCircuit {
  id: number;
  heatingProductionUnitId: number;
  name: string;
  comments: string;
  role: HeatingDistributionCircuitRole;
  monitoring: HeatingCircuitMonitoringLevel;
  isSchedulingRegulation: boolean;
  jobsConfiguration: JobConfiguration;
  isPredictiveRegulation: boolean;
  instrumentGroupId: number | null;
  metadata: HeatingDistributionCircuitMetadatas;
}

export interface HeatingDistributionCircuitControls {
  predictiveLocalSwitch: boolean;
  predictiveRemoteSwitch: boolean;
  isWatchdogReleased: boolean;
  v3vRegulation: boolean | null;
}

export interface HeatingDistributionCircuitMeasurement {
  id: number;
  heatingDistributionId: number;
  timestamp: string;
  waterFlowTemperature: number;
  waterReturnTemperature: number;
  threeWayValveTravelPercent: number;
  offsetFromPredictiveSystem: number;
  offsetFromPLC: number;
  waterFlowSetPointTemperature: number;
  waterFlowTemperaturePlusOffsetCalculated: number;
  waterFlowTemperatureEmulated: number;
  compositeOutsideTemperature: number;
  waterFlowTemperatureApplied: number;
  controls: HeatingDistributionCircuitControls;
}

export type OnOff = "on" | "off";

export interface HeatingDistributionCircuitPredictiveSystem {
  localState: OnOff;
  remoteState: OnOff;
  offset: number;
}

export type SchedulingMode =
  | "regulated"
  | "auto"
  | "comfort"
  | "precomfort"
  | "economy"
  | "frostfree";

export interface HeatingDistributionCircuitWatchdogState {
  plcId: number;
  presetValue: number;
  counterValue: number;
  state: OnOff;
  isWatchdogActive: boolean;
}
export interface HeatingDistributionCircuitHeatingCurveState {
  dayCorrection: number;
  nightCorrection: number;
  x1: number;
  x1_origin: AbscissaOrigin;
  y1: number;
  x2: number;
  x2_origin: AbscissaOrigin;
  y2: number;
  x3?: number;
  x3_origin?: AbscissaOrigin;
  y3?: number;
  x4?: number;
  x4_origin?: AbscissaOrigin;
  y4?: number;
}

export interface HeatingInstallationEnergyConsumptions {
  heatingEnergy: number;
  waterFlowEnergy: number;
  plantActiveEnergy: number;
  plantReactiveEnergy: number;
}

export interface HeatingDistributionCircuitCurvePoint {
  outsideTemperature: number;
  waterFlowTemperature: number;
}

export interface HeatingCurveHistory {
  timestamp: string;
  x1: number;
  x1_origin: AbscissaOrigin;
  y1: number;
  x2: number;
  x2_origin: AbscissaOrigin;
  y2: number;
  x3?: number;
  x3_origin?: AbscissaOrigin;
  y3?: number;
  x4?: number;
  x4_origin?: AbscissaOrigin;
  y4?: number;
  noHeatingTemperature?: number;
  dayCorrection?: number;
  nightCorrection?: number;
}

export type AbscissaOrigin = "static" | "dynamic";

export interface HeatingDistributionCircuitCurve {
  id: number;
  heatingDistributionId: number;
  timestamp: string;
  x1: number;
  x1_origin: AbscissaOrigin;
  y1: number;
  x2: number;
  x2_origin: AbscissaOrigin;
  y2: number;
  x3?: number;
  x3_origin?: AbscissaOrigin;
  y3?: number;
  x4?: number;
  x4_origin?: AbscissaOrigin;
  y4?: number;
  noHeatingTemperature: number;
}

export type BatteryMeasurement =
  | { timestamp: string; type: "voltage"; value: string }
  | { timestamp: string; type: "flag"; value: "true" | "false" }
  | { timestamp: string; type: "percentage"; value: string }
  | { timestamp: string; type: "current_consumption"; value: string }
  | { type: "unknown" };

export enum InstrumentCategory {
  Other = "other",
  Froid = "cold",
  Confort = "comfort",
  Chaud = "hot",
  ExternalTemperature = "external",
}

export interface InstrumentDisposition {
  building: string;
  circuit: string;
  vertical: string;
  floor: string;
  flatNumber: string;
  flatDetails: {
    orientation: string;
    transmitterType: string;
    glazing: string;
    comments: string;
  };
}
export interface MappingSession {
  id: number;
  name: string;
  creationDate: string;
  userId: string;
}

export interface Instrument {
  id: number;
  serialNumber: string;
  name: string;
  type: string;
  comments: string;
  supplierId: number;
  siteId: number;
  battery: BatteryMeasurement;
  dataSource: string;
  category?: InstrumentCategory;
  disposition?: InstrumentDisposition;
}

export interface InstrumentDeploymentSession {
  id: number;
  name: string;
  slug: string;
  supplierId: number;
  creationDate: string;
  userId: string;
  status: string;
  mappingSessionId: number;
}

export interface InstrumentStock {
  id: number;
  deploymentSessionId: number;
  serialNumber: string;
  name: string;
  hardwareName: string;
  appEui: string;
  devEui: string;
  appKey: string;
  commercialRef: string;
  deliveryNumber: string;
  instrumentId?: number;
}

export type InstrumentStockCreate = Omit<InstrumentStock, "id" | "name" | "hardwareName">;

export interface ImageElementPosition {
  xPercentage: number;
  yPercentage: number;
}

export interface AbstractImagePosition {
  imageId: number;
  elementId: number;
  xPercentage: number;
  yPercentage: number;
}

export interface ImagePosition {
  imageId: number;
  labelId: number;
  xPercentage: number;
  yPercentage: number;
}
export interface ImageInstrumentPosition {
  imageId: number;
  instrumentId: number;
  xPercentage: number;
  yPercentage: number;
}

export interface ImageInstrumentGroupPosition {
  imageId: number;
  instrumentGroupId: number;
  xPercentage: number;
  yPercentage: number;
}

export type InstrumentInstrumentGroupType = "instrument" | "instrument-group";

// Interface used to combine instruments and instrument groups
export interface InstrumentInstrumentGroup {
  id: number;
  type: InstrumentInstrumentGroupType;
  name?: string;
  comments?: string;
}
export interface InstrumentsStats {
  failureExpirationTime: number;
  failedCount: number;
  lowBatteryCount: number;
  unknownBatteryCount: number;
  totalCount: number;
}

export interface InstrumentStatsResponse {
  globalStats: InstrumentsStats;
  siteInstrumentsStats: {
    siteId: number;
    instrumentsStats: InstrumentsStats;
  }[];
  supplierInstrumentsStats: {
    supplierId: number;
    instrumentsStats: InstrumentsStats;
  }[];
}

// key being file UID
export interface SiteImage {
  id: number;
  uid: string;
  url: string;
  name: string;
  data?: string; // base64 encoded image data - might be removed if not using blob
  status?: UploadFileStatus; // used only for Upload component
}

export interface InstrumentMeasurement {
  id: number;
  instrumentId: number;
  timestamp: string;
  temperatureMeasurement: number;
  humidityMeasurement?: number;
  co2Measurement?: number;
}

export interface AggregatedMeasurement {
  timestamp: string;
  temperatureMeasurement: number;
  humidityMeasurement?: number;
  co2Measurement?: number;
}

export interface EnergyRegressionParams {
  alpha: number;
  r2: number;
  batchSize: number;
}

export interface SitePhysics {
  siteId: number;
  alpha: number;
  r2: number;
  startDate: string;
  endDate: string;
}

export type logbookType = "message" | "config";

export interface SiteLogbookInput {
  content: string;
}

export type SiteLogbook = SiteLogbookInput & {
  id: number;
  siteId: number;
  userId: string;
  timestamp: string;
  type: logbookType;
};

export enum InstrumentGroupCategory {
  Reference = "reference",
  Aucune = "",
}

export interface InstrumentGroup {
  id: number;
  name: string;
  comments: string;
  siteId: number;
  instrumentIds: number[];
  category: InstrumentGroupCategory;
}

export type InstrumentRow = Instrument & { uid: string; type: "instrument"; isInGroup?: boolean };

export type InstrumentGroupRow = InstrumentGroup & {
  uid: string;
  type: "group";
  serialNumber: "N/A";
  battery: { type: "unknown" };
  children: InstrumentRow[];
  isInGroup?: boolean;
};

export interface ComfortMonitoring {
  site: Site;
  comfortRange: ComfortRange;
  groupComforts: GroupComfortMonitoring[];
}

export interface GroupComfortMonitoring {
  group: InstrumentGroup;
  referenceTemperature: number;
  referenceTemperature1Day: number;
  referenceTemperature7Days: number;
}

export interface ComfortIndicator {
  site: Site;
  comfortRange: ComfortRange;
  energySaving: EnergySaving;
  minTemperature: number;
  lowestMeanTemperature: number;
  breakdowns: ComfortIndicatorBreakdowns;
}

export interface ComfortIndicatorBreakdowns {
  workingInstruments: number;
  totalInstruments: number;
}

export interface MonthlyIndicator {
  id: number;
  siteId: number;
  heatingConsumption: number;
  heatingConsumptionUnit: string;
  date: string;
  hdd: number;
}

export type InstrumentTableRow = InstrumentRow | InstrumentGroupRow;

export type InstrumentGroupCreate = Omit<InstrumentGroup, "id">;

export type InstrumentGroupMeasurement = InstrumentMeasurement & { type: "instrument-group" };

export type RoleID = string;

export interface Role {
  id: RoleID;
  name: string;
  description: string;
}

export interface InstrumentGroupMeasurementService {
  fetch(params: { igIds: string[]; from: number; to: number }): Promise<APIResult<Measurement[]>>;
  fetchReference(params: {
    igIds: string[];
    from: number;
    to: number;
  }): Promise<APIResult<InstrumentGroupMeasurement[]>>;
}

export interface InstrumentService {
  fetchOne(params: { iId: string }): Promise<APIResult<Instrument>>;

  fetchMany(params: { iIds: string[] }): Promise<APIResult<Instrument[]>>;

  update(instrument: Instrument): Promise<APIResult<Instrument>>;

  save(instrument: Instrument): Promise<APIResult<Instrument>>;

  updateCategory(iids: number[], category: InstrumentCategory): Promise<APIResult<void>>;

  delete(params: { iId: string }): Promise<APIResult<{}>>;

  deleteMeasurements(params: { iId: number; from: number; to: number }): Promise<APIResult<{}>>;

  latestMeasurements(params: { iIds: string[] }): Promise<APIResult<{ [k: string]: Measurement }>>;
}
export interface BuildingService {
  getImagesPositions(params: { imageIds: number[] }): Promise<APIResult<ImagePosition[]>>;

  saveImagePositions(params: { ips: ImagePosition[] }): Promise<APIResult<void>>;

  deleteImagePositions(params: { ips: ImagePosition[] }): Promise<APIResult<void>>;
}
export interface RegulationService {
  fetchRegulationMeasurements(params: {
    sId: string;
    hdcId: number;
    from: number;
    to: number;
  }): Promise<APIResult<RegulationMeasurement[]>>;

  fetchLatestRegulationMeasurement(params: {
    sId: string;
    hdcId: number;
  }): Promise<APIResult<RegulationMeasurement>>;

  fetchLatestSchedulingMeasurement(params: {
    sId: string;
    hdcId: string;
  }): Promise<APIResult<SchedulingMeasurement>>;

  fetchSchedulingMeasurements(params: {
    sId: string;
    hdcId: number;
    from: number;
    to: number;
  }): Promise<APIResult<SchedulingMeasurement[]>>;

  fetchSchedulingEvents(params: {
    sId: string;
    hdcId: number;
    from: number;
    to: number;
  }): Promise<APIResult<SchedulingEvent[]>>;

  fetchSchedulingMode(params: { sId: string; hdcId: string }): Promise<APIResult<{ mode: string }>>;

  downloadRegulationMeasurements(params: {
    sId: string;
    hic: string;
    from: number;
    to: number;
    fileName: string;
  }): Promise<void>;

  updateHeatingDistributionCircuitRegulationParameters(params: {
    slug: string;
    hicId: string;
    regulationParams: RegulationParameters;
  }): Promise<APIResult<{ params: SchedulingParameters }>>;

  saveHeatingDistributionCircuitRegulationParameters(params: {
    slug: string;
    hicId: string;
    regulationParams: RegulationParameters;
  }): Promise<APIResult<{ params: SchedulingParameters }>>;
}

export interface InstrumentGroupService {
  fetchOne(params: { igId: string }): Promise<APIResult<InstrumentGroup>>;

  fetchMany(params: { igIds: string[] }): Promise<APIResult<InstrumentGroup[]>>;

  save(instrumentGroup: InstrumentGroupCreate): Promise<APIResult<InstrumentGroup>>;

  delete(params: { igId: string }): Promise<APIResult<{}>>;
}

export interface SitesAlerts {
  [key: string]: SiteAlerts;
}

export interface SiteAlerts {
  instrumentGroupAlerts: InstrumentGroupAlert[];
  distributionCircuitAlerts: HeatingDistributionCircuitAlert[];
  productionUnitAlerts: HeatingProductionUnitAlert[];
}

export interface HeatingDistributionCircuitAlert {
  id: number;
  heatingDistributionCircuitId: number;
  name: string;
  userMail: string;
  comments: string;
  lowerThreshold?: string;
  upperThreshold?: string;
  alertType: string;
  triggerComment: string;
  isDefault: boolean;
  isActive: boolean;
  isTriggered: boolean;
  triggerDate: string;
  lastCheckDate: string;
  isAcknowledged: boolean;
}

export interface HeatingProductionUnitAlert {
  id: number;
  heatingProductionUnitId: number;
  name: string;
  userMail: string;
  comments: string;
  lowerThreshold?: string;
  upperThreshold?: string;
  alertType: string;
  triggerComment: string;
  isDefault: boolean;
  isActive: boolean;
  isTriggered: boolean;
  triggerDate: string;
  lastCheckDate: string;
  isAcknowledged: boolean;
}

export interface InstrumentGroupAlert {
  id: number;
  instrumentGroupId: number;
  name: string;
  userMail: string;
  comments: string;
  lowerThreshold?: string;
  upperThreshold?: string;
  alertType: string;
  triggerComment: string;
  isDefault: boolean;
  isActive: boolean;
  isTriggered: boolean;
  triggerDate: string;
  lastCheckDate: string;
  isAcknowledged: boolean;
}

export interface InstrumentGroupAlertCreate {
  instrumentGroupId: number;
  name: string;
  comments: string;
  userMail: string;
  alertType: string;
  lowerThreshold?: string;
  upperThreshold?: string;
  isDefault: boolean;
}

export interface InstrumentGroupAlertService {
  fetchMany(): Promise<APIResult<InstrumentGroupAlert[]>>;

  create(alert: InstrumentGroupAlertCreate): Promise<APIResult<InstrumentGroupAlert>>;

  update(alert: InstrumentGroupAlert): Promise<APIResult<InstrumentGroupAlert>>;

  delete(params: { igaId: string }): Promise<APIResult<{}>>;
}

export type Measurement = InstrumentMeasurement | InstrumentGroupMeasurement;

export enum RequestTimeout {
  defaultTimeout = 30000,
  exportTimeout = 60000,
}

export interface MeasurementService {
  fetch(params: { iIds: string[]; from: number; to: number }): Promise<APIResult<Measurement[]>>;

  download(params: { iId: string; from: number; to: number; fileName: string }): Promise<void>;
}

export interface Address {
  streets: string[];
  postalCode: number;
  city: string;
  country: string;
}

export interface ReferenceConsumption {
  value: number;
  types: string[];
  period: string;
  dju: number;
}

export interface SiteDescription {
  category: string;
  buildings: number;
  flats: number;
  energyTypes: string[];
  surfaceType: string;
}

export interface ClientDescription {
  clientName: string;
  syndic: string;
  manager: string;
  creation: string;
  expiration: string;
}

export interface HeatingInstallationDescription {
  productionUnitNumber: number;
  productionUnitTypes: string[];
  transmitterTypes: string[];
  circuits: string;
  controllers: string[];
}

export interface ReportFormData {
  startDate: Moment;
  endDate: Moment;
  startDateRef: Moment;
  endDateRef: Moment;
  selectedGroupIds: number[];
  selectedEnergyMeterIds: number[];
  selectedPages: string[];
  directorParagraph: string;
}

export interface AdminBanner {
  id: number;
  content: string;
  startTime: string;
  endTime: string;
  informationLevel: string;
}

export type AdminBannerCreate = Omit<AdminBanner, "id">;

export interface EnergySaving {
  consumption: number;
  consumptionSaved: number;
  alpha: number;
  r2: number;
  batchSize: number;
}

export interface SiteMetadatas {
  address: Address;
  refConsumption: ReferenceConsumption;
  siteDescription: SiteDescription;
  clientDescription: ClientDescription;
  heatingInstallationDescription: HeatingInstallationDescription;
}

export interface Site {
  id: number;
  slug: string;
  name: string;
  trigram: string;
  weatherLocationID: number;
  surfaceArea: number;
  comments: string;
  coordinates: Coordinates;
  metadata?: SiteMetadatas;
  currentHeatingSeason: HeatingSeason;
  heatingReferentUser: UserManagementUser;
  lastAudit: string;
  exploitation: boolean;
}

export type SiteCreate = Omit<
  Site & { heatingReferentUserId: string },
  | "id"
  | "comfortRange"
  | "currentHeatingSeason"
  | "heatingReferentUser"
  | "lastAudit"
  | "exploitation"
>;
export type SiteFormInput = Omit<
  Site & { heatingReferentUserId: string },
  | "id"
  | "slug"
  | "comfortRange"
  | "currentHeatingSeason"
  | "heatingReferentUser"
  | "lastAudit"
  | "exploitation"
>;

export interface SiteService {
  fetch(): Promise<APIResult<Site[]>>;

  fetchOne(params: { sId: string }): Promise<APIResult<Site>>;

  fetchEnergyMeters(params: { sId: string }): Promise<APIResult<EnergyMeter[]>>;

  fetchInstruments(params: {
    sId: string;
    supplierIds?: number[];
  }): Promise<APIResult<Instrument[]>>;

  fetchInstrumentGroups(params: { sId: string }): Promise<APIResult<InstrumentGroup[]>>;

  fetchHeatingSeasons(params: { sId: string }): Promise<APIResult<HeatingSeason[]>>;

  latestMeasurements(params: { sId: string }): Promise<APIResult<{ [k: string]: Measurement }>>;

  periodMeasurements(params: {
    sId: string;
    from: number;
    to: number;
  }): Promise<APIResult<{ [k: string]: AggregatedMeasurement }>>;

  downloadSiteMeasurements(params: {
    sId: string;
    from: number;
    to: number;
    fileName: string;
  }): Promise<void>;

  downloadInstrumentsMeasurements(params: {
    fileName: string;
    instrumentIds: number[];
    groupIds: number[];
    withWeather: boolean;
    from: number;
    to: number;
    sId: string;
  }): Promise<void>;

  downloadSiteConsumption(params: {
    sId: string;
    fileName: string;
    from: number;
    to: number;
  }): Promise<void>;

  downloadSiteReport(params: ReportFormData & { sId: string }): Promise<void>;

  create(site: SiteCreate): Promise<APIResult<Site>>;

  update(site: Partial<Site>, slug: string): Promise<APIResult<Site>>;

  fetchHeatingDistributionCircuitV3VRegulation(params: {
    sId: string;
    hdcId: string;
  }): Promise<APIResult<boolean>>;

  updateHeatingSeason(params: {
    sId: string;
    heatingSeason: Partial<HeatingSeason>;
  }): Promise<APIResult<HeatingSeason>>;

  fetchHeatingProductionUnit(params: { sId: string }): Promise<APIResult<HeatingProductionUnit[]>>;

  fetchSitePlcs(params: { sId: string }): Promise<APIResult<PLC[]>>;

  saveSitePlc(params: { sId: string; plc: PLC }): Promise<APIResult<number>>;

  updateSitePlc(params: {
    sId: string;
    plc: Partial<PLC> & { id: number };
  }): Promise<APIResult<number>>;

  saveSiteEnergyMeter(params: { sId: string; em: EnergyMeter }): Promise<APIResult<number>>;

  updateSiteEnergyMeter(params: {
    sId: string;
    em: Partial<EnergyMeter> & { id: number };
  }): Promise<APIResult<number>>;

  saveSiteHeatingProductionUnit(params: {
    sId: string;
    heatingProductionUnit: HeatingProductionUnit;
  }): Promise<APIResult<number>>;

  updateSiteHeatingProductionUnit(params: {
    sId: string;
    heatingProductionUnit: Partial<HeatingProductionUnit> & { id: number };
  }): Promise<APIResult<number>>;

  saveSiteHeatingDistributionCircuit(params: {
    sId: string;
    heatingDistributionCircuit: HeatingDistributionCircuit;
  }): Promise<APIResult<number>>;

  updateSiteHeatingDistributionCircuit(params: {
    sId: string;
    heatingDistributionCircuit: Partial<HeatingDistributionCircuit> & { id: number };
  }): Promise<APIResult<number>>;

  fetchHeatingProductionUnitMeasurements(params: {
    sId: string;
  }): Promise<APIResult<HeatingProductionUnitMeasurement[]>>;

  fetchProductionDataPoints(params: {
    sId: string;
    hpuId: number;
  }): Promise<APIResult<ProductionDataPoint[]>>;

  saveProductionDataPoint(params: {
    sId: string;
    hpuId: number;
    pdp: ProductionDataPoint;
  }): Promise<APIResult<number>>;

  updateProductionDataPoint(params: {
    sId: string;
    hpuId: number;
    pdp: ProductionDataPoint;
  }): Promise<APIResult<number>>;

  deleteProductionDataPoint(params: {
    sId: string;
    hpuId: number;
    pdpId: number;
  }): Promise<APIResult<any>>;

  fetchDistributionDataPoints(params: {
    sId: string;
    hdcId: number;
  }): Promise<APIResult<DistributionDataPoint[]>>;

  saveDistributionDataPoint(params: {
    sId: string;
    hdcId: number;
    ddp: DistributionDataPoint;
  }): Promise<APIResult<number>>;

  updateDistributionDataPoint(params: {
    sId: string;
    hdcId: number;
    ddp: DistributionDataPoint;
  }): Promise<APIResult<number>>;

  deleteDistributionDataPoint(params: {
    sId: string;
    hdcId: number;
    ddpId: number;
  }): Promise<APIResult<any>>;

  fetchHeatingDistributionCircuits(params: {
    sId: string;
  }): Promise<APIResult<HeatingDistributionCircuit[]>>;

  fetchHeatingDistributionMeasurements(params: {
    sId: string;
    hdcId: number;
    from: number;
    to?: number;
  }): Promise<APIResult<HeatingDistributionCircuitMeasurement[]>>;

  updateHeatingDistributionCircuitInstrumentGroupId(params: {
    sId: string;
    hdcId: number;
    instrumentGroupId: number | null;
  }): Promise<APIResult<number>>;

  fetchHeatingDistributionCurve(params: {
    sId: string;
    hdcId: number;
  }): Promise<APIResult<HeatingDistributionCircuitCurve[]>>;

  fetchHeatingDistributionCurveHistory(params: {
    sId: string;
    hicId: string;
  }): Promise<APIResult<HeatingCurveHistory[]>>;

  fetchHeatingDistributionCircuitPredictiveSystem(params: {
    sId: string;
    hicId: string;
  }): Promise<APIResult<HeatingDistributionCircuitPredictiveSystem>>;

  updateHeatingDistributionCircuitSchedulingMode(params: {
    slug: string;
    hicId: string;
    mode: SchedulingMode;
  }): Promise<APIResult<{ mode: SchedulingMode }>>;

  toggleHeatingDistributionCircuitPredictiveSystem(params: {
    sId: string;
    hicId: string;
  }): Promise<APIResult<HeatingDistributionCircuitPredictiveSystem>>;

  fetchHeatingDistributionCircuitWatchdogState(params: {
    sId: string;
    hdcId: string;
  }): Promise<APIResult<HeatingDistributionCircuitWatchdogState>>;

  togglePlcWatchdogState(params: {
    sId: string;
    plcId: string;
  }): Promise<APIResult<{ isWatchdogActive: boolean }>>;
}

export enum UnitOfMeasure {
  CubicMeters = "cubic_meter",
  MegaWattHour = "megawatt_hour",
}

export interface UserManagementUser {
  id: string;
  name: string;
  email: string;
  phoneNumber: string;
  isNewsletterReceiver: boolean;
  authorizedSites: number[];
  roles: RoleID[];
  type: string;
  lastLogin?: string;
  passwordChangeTicket?: string;
}

export interface UserRequiredActions {
  heatingSeason: boolean;
}
export interface Weather {
  id: number;
  timestamp: string;
  // The air temperature (2 m above ground) in °C.
  airTemperature: number;
  // The relative humidity (2 m above ground) in %.
  relativeHumidity: number;
  // The total precipitation in mm.
  precipitation: number;
  // The wind speed in km/h.
  windSpeed: number;
  // The wind direction in degrees (°).
  windDirection: number;
  // The diffuse radiation on the horizontal plane in W/m2
  diffuseRadiation: number;
  // The direct normal (beam) radiation on the horizontal plane in W/m2
  directRadiation: number;
  // The direct normal radiation in W/m2
  directNormalRadiation: number;
  // The global radiation on the horizontal plane in W/m2
  globalRadiation: number;
}

// Daily DHH
export interface DailyDHH {
  date: string;
  hdd: number;
  hourHdd: number;
}

export interface HeatingPeriod {
  key: string;
  startTime: string;
  endTime: string;
  isDefaultPeriod: boolean;
}

export interface SeasonStarChecks {
  instrumentsInventory: boolean;
  referenceGroupCorrectConstitution: boolean;
  coldGroupCorrectConstitution: boolean;
  temporaryGroupsCorrectConstitution: boolean;
  hotGroupCorrectConstitution: boolean;
  workingOutsideTemperature: boolean;
  workingEnergyMeters: boolean;
  comfortRangeValidation: boolean;
  startTimeValidation: boolean;
}
export interface HeatingSeason {
  id?: number;
  key: string;
  startTime: string;
  endTime: string;
  isDefaultPeriod: boolean;
  comfortRange: ComfortRange;
  isDefaultComfortRange: boolean;
  comment: string;
  seasonStartChecks: SeasonStarChecks;
  isReference: boolean;
}

export enum WeatherLocationEnum {
  Paris = "paris",
}

export interface WeatherLocation {
  id: number;
  name: string;
  coordinates: Coordinates;
}

export interface WeatherData {
  weather: Weather[];
  dailyHDD: DailyDHH[];
  weatherLocation: WeatherLocation;
}

export interface WeatherService {
  fetchWeatherData(params: {
    sid: number;
    from: number;
    to: number;
  }): Promise<APIResult<WeatherData>>;
  fetchWeatherLocations(): Promise<APIResult<WeatherLocation[]>>;
}

export type UserManagementUserCreate = Omit<UserManagementUser, "id" | "lastLogin">;
export type UserManagementUserUpdate = Omit<UserManagementUser, "lastLogin">;

export interface UserManagementService {
  downloadUsers(params: { fileName: string }): Promise<void>;

  fetch(): Promise<APIResult<UserManagementUser[]>>;

  fetchRequiredActions(): Promise<APIResult<UserRequiredActions>>;

  fetchRoles(): Promise<APIResult<Role[]>>;

  fetchUserRoles(userId: string): Promise<APIResult<Role[]>>;

  create(user: UserManagementUserCreate): Promise<APIResult<UserManagementUser>>;

  update(user: UserManagementUserUpdate): Promise<APIResult<UserManagementUser>>;

  delete(params: { uId: string }): Promise<APIResult<{}>>;
}

export function renderUnitOfMeasure(u: UnitOfMeasure): string {
  switch (u) {
    case UnitOfMeasure.CubicMeters:
      return " Mètres cubes";
    case UnitOfMeasure.MegaWattHour:
      return " Mégawatt-heure";
  }
}

const axios = Axios.create({
  baseURL: "/api/",
  timeout: RequestTimeout.defaultTimeout,
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: "repeat" }),
});

const axiosForExport = Axios.create({
  baseURL: "/api/",
  timeout: RequestTimeout.exportTimeout,
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: "repeat" }),
});

export function saveBlob(blob: Blob, { name }: { name: string }) {
  const link = document.createElement("a");

  link.href = window.URL.createObjectURL(blob);
  link.download = name;
  link.click();
}

abstract class APIService {
  private readonly axiosCancelTokenSource: CancelTokenSource;

  constructor(protected readonly getAuthToken: () => Promise<string>) {
    this.axiosCancelTokenSource = Axios.CancelToken.source();
  }

  private static authHeader(token: string) {
    return { Authorization: `Bearer ${token}` };
  }

  private static errorFromHttpStatus(status: number) {
    switch (status) {
      case 400:
        return Errors.BadRequest;
      case 401:
      case 403:
        return Errors.Unauthorized;
      case 404:
        return Errors.NotFound;
      default:
        return Errors.ServerError;
    }
  }

  cancelRequests(message?: string) {
    this.axiosCancelTokenSource.cancel(message);
  }

  private async makeAxiosRequest<T>(
    method: HttpMethod,
    url: string,
    accept: ContentType = ContentType.JSON,
    params: object = {},
    data: object | FormData = {},
    exportRequest: boolean = false,
    respTypeArray: boolean = false,
    contentType: ContentType = ContentType.JSON,
    imageRequest: boolean = false,
  ): Promise<APIResult<T>> {
    try {
      const axiosConfig = exportRequest || imageRequest ? axiosForExport : axios;
      const respType = respTypeArray ? "arraybuffer" : "json";

      const response = await axiosConfig.request<T>({
        method,
        url,
        cancelToken: this.axiosCancelTokenSource.token,
        headers: {
          ...APIService.authHeader(await this.getAuthToken()),
          accept,
          ...{ "Content-type": contentType },
        },
        responseType: respType,
        data,
        params,
      });

      return { status: "success", data: response.data };
    } catch (e) {
      console.log(e);
      if (Axios.isCancel(e)) {
        return {
          status: "error",
          message: "",
          error: Errors.RequestCancelled,
        };
      } else {
        return {
          status: "error",
          error: APIService.errorFromHttpStatus(e.response?.status),
          message: e.response?.data,
        };
      }
    }
  }

  async downloadYearConsumptionCSV(
    method: "GET" | "POST",
    url: string,
    params: { fileName: string } & { [k: string]: any },
  ): Promise<void> {
    const { fileName, ...rest } = params;

    const res = await this.makeAxiosRequest<any>(method, url, ContentType.JSON, rest, {}, true);

    const separator = ";";
    if (res.status === "success") {
      const data = res.data.data;
      const csvColumns = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      // const csvColumnsFr = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Decembre"]
      const instruments: any = [];
      data.forEach((element: any) => {
        instruments.push(element);
      });

      var csvString = "Nom du compteur" + separator + csvColumns.join(separator) + "\n";
      if (csvColumns.length > 0) {
        instruments.forEach((instrument: any) => {
          var newLine = instrument.EnergyMeterName + separator;
          csvColumns.forEach((month) => {
            newLine += instrument.YearConsumption[month] + separator;
          });
          csvString += newLine.slice(0, -1) + "\n"; // we remove last separator at end of line
        });
      }
      saveBlob(new Blob([csvString], { type: ContentType.CSV }), {
        name: fileName,
      });
    }
  }

  async makeAxiosJSONRequest<T>(
    method: HttpMethod,
    url: string,
    params: object = {},
    data: object | undefined = undefined,
  ): Promise<APIResult<T>> {
    const res = await this.makeAxiosRequest<APIResponse<T>>(
      method,
      url,
      ContentType.JSON,
      params,
      data,
    );

    if (res.status === "success") return { status: "success", data: res.data.data };
    else return res;
  }

  async makeAxiosMultiformDataRequest<T>(
    method: HttpMethod,
    url: string,
    params: object = {},
    file: File,
  ): Promise<APIResult<T>> {
    const formData = new FormData();
    formData.append("myFile", file);
    const res = await this.makeAxiosRequest<APIResponse<T>>(
      method,
      url,
      ContentType.JSON,
      params,
      formData,
      false,
      false,
      ContentType.MULTIPART,
      true,
    );

    if (res.status === "success") return { status: "success", data: res.data.data };
    else return res;
  }

  async makeAxiosPDFDownloadRequest(
    method: "GET",
    url: string,
    params: { [k: string]: any },
  ): Promise<void> {
    const { ...rest } = params;
    const res = await this.makeAxiosRequest<BlobPart>(
      method,
      url,
      ContentType.CSV,
      rest,
      {},
      true,
      true,
    );

    if (res.status === "success") {
      saveBlob(new Blob([res.data], { type: ContentType.PDF }), {
        name: "Rapport.pdf",
      });

      return undefined;
    }

    throw res.error;
  }

  async makeAxiosXLSXDownloadRequest(
    method: "GET",
    url: string,
    params: { fileName: string } & { [k: string]: any },
  ): Promise<void> {
    const { fileName, ...rest } = params;
    const res = await this.makeAxiosRequest<BlobPart>(
      method,
      url,
      ContentType.XLSX,
      rest,
      {},
      true,
      true,
    );

    if (res.status === "success") {
      saveBlob(new Blob([res.data], { type: ContentType.XLSX }), {
        name: fileName,
      });

      return undefined;
    }

    throw res.error;
  }

  async makeAxiosCSVDownloadRequest(
    method: "GET" | "POST",
    url: string,
    params: { fileName: string } & { [k: string]: any },
  ): Promise<void> {
    const { fileName, ...rest } = params;
    const res = await this.makeAxiosRequest<BlobPart>(method, url, ContentType.CSV, rest, {}, true);

    if (res.status === "success") {
      saveBlob(new Blob([res.data], { type: ContentType.CSV }), {
        name: fileName,
      });

      return undefined;
    }

    throw res.error;
  }

  async makeAxiosInstrumentsCSVDownloadRequest(
    method: "GET" | "POST",
    url: string,
    params: { fileName: string } & { [k: string]: any },
  ): Promise<void> {
    const { fileName, ...rest } = params;

    const res = await this.makeAxiosRequest<any>(method, url, ContentType.JSON, rest, {}, true);

    const separator = ";";
    if (res.status === "success") {
      const data = res.data.data;
      const csvColumns = Object.keys(data).sort((a, b) =>
        String(b) === String("weather_air_temperature") ? 1 : -1,
      );
      var csvString = "Date_Heure" + separator + csvColumns.join(separator) + "\n";
      if (csvColumns.length > 0) {
        let indexMaxLength = 0;
        csvColumns.forEach((column, index) => {
          if (
            Object.keys(data[column]).length > Object.keys(data[csvColumns[indexMaxLength]]).length
          )
            indexMaxLength = index;
        });
        const timestamps = Object.keys(data[csvColumns[indexMaxLength]]);
        timestamps.forEach((timestamp) => {
          const formattedDate = new Date(timestamp).toLocaleString().replace(",", "");
          var newLine = formattedDate + separator;
          csvColumns.forEach((column: any) => {
            let dataOfInterest =
              data[column][timestamp] !== undefined ? data[column][timestamp] : "";
            if (dataOfInterest) dataOfInterest = dataOfInterest.toFixed(1);
            newLine += dataOfInterest.toString().replace(".", ",") + separator; // séparation des décimales en virgules
          });
          csvString += newLine.slice(0, -1) + "\n"; // we remove last separator at end of line
        });
      }
      saveBlob(new Blob([csvString], { type: ContentType.CSV }), {
        name: fileName,
      });
    }
  }
}

export class AuthAPIService extends APIService implements AuthService {
  private route = "/auth";

  createFirebaseCustomToken(): Promise<APIResult<{ token: string }>> {
    return this.makeAxiosJSONRequest("GET", `${this.route}/firebase/token`);
  }
}

export class EnergyMeterAPIService extends APIService implements EnergyMeterService {
  private route = "/energy-meters";

  fetchOne(params: { emId: string }): Promise<APIResult<EnergyMeter>> {
    return this.makeAxiosJSONRequest("GET", `${this.route}/${params.emId}`);
  }

  fetchMany(params: { emIds: string[] }): Promise<APIResult<EnergyMeter[]>> {
    return this.makeAxiosJSONRequest("GET", this.route, { emid: params.emIds });
  }

  makeGrdfConsentDemand(params: { idPce: string; demand: any }): Promise<APIResult<any>> {
    return this.makeAxiosJSONRequest(
      "PUT",
      `${this.route}/consent/${params.idPce}`,
      {},
      params.demand,
    );
  }
}

export class EnergyMeasurementAPIService extends APIService implements EnergyMeasurementService {
  private route = "/energy-meters";

  fetchMeasurements(params: {
    emIds: string[];
    from: number;
    to: number;
  }): Promise<APIResult<EnergyMeasurement[]>> {
    const { emIds: emid, from, to } = params;
    return this.makeAxiosJSONRequest("GET", `${this.route}/measurements`, {
      emid,
      from,
      to,
    });
  }

  fetchConsumptions(params: {
    emIds: string[];
    from: number;
    to: number;
  }): Promise<APIResult<EnergyConsumption[]>> {
    const { emIds: emid, from, to } = params;
    return this.makeAxiosJSONRequest("GET", `${this.route}/consumptions`, {
      emid,
      from,
      to,
    });
  }

  downloadConsumptions(params: {
    mId: string;
    from: number;
    to: number;
    sId: number;
    fileName: string;
  }): Promise<void> {
    return this.makeAxiosCSVDownloadRequest(
      "GET",
      `${this.route}/consumptions/export/${params.sId}`,
      {
        emid: params.mId,
        from: params.from,
        to: params.to,
        fileName: params.fileName,
      },
    );
  }
}

export class RegulationAPIService extends APIService implements RegulationService {
  private route = "/sites";

  fetchRegulationMeasurements(params: {
    sId: string;
    hdcId: number;
    from: number;
    to: number;
  }): Promise<APIResult<RegulationMeasurement[]>> {
    return this.makeAxiosJSONRequest(
      "GET",
      `${this.route}/${params.sId}/production-units/distribution-circuits/${params.hdcId}/regulation-measurement`,
      {
        from: params.from,
        to: params.to,
      },
    );
  }

  fetchLatestRegulationMeasurement(params: {
    sId: string;
    hdcId: number;
  }): Promise<APIResult<RegulationMeasurement>> {
    return this.makeAxiosJSONRequest(
      "GET",
      `${this.route}/${params.sId}/production-units/distribution-circuits/${params.hdcId}/regulation-measurement/latest`,
    );
  }

  fetchRegulationParameters(params: {
    sId: string;
    hdcId: string;
  }): Promise<APIResult<RegulationParameters>> {
    return this.makeAxiosJSONRequest(
      "GET",
      `${this.route}/${params.sId}/production-units/distribution-circuits/${params.hdcId}/regulation/parameters`,
    );
  }

  fetchLatestSchedulingMeasurement(params: {
    sId: string;
    hdcId: string;
  }): Promise<APIResult<SchedulingMeasurement>> {
    return this.makeAxiosJSONRequest(
      "GET",
      `${this.route}/${params.sId}/production-units/distribution-circuits/${params.hdcId}/scheduling/measurements/latest`,
    );
  }

  fetchSchedulingMeasurements(params: {
    sId: string;
    hdcId: number;
    from: number;
    to: number;
  }): Promise<APIResult<SchedulingMeasurement[]>> {
    return this.makeAxiosJSONRequest(
      "GET",
      `${this.route}/${params.sId}/production-units/distribution-circuits/${params.hdcId}/scheduling/measurements`,
      {
        hdcId: params.hdcId,
        from: params.from,
        to: params.to,
      },
    );
  }

  fetchSchedulingEvents(params: {
    sId: string;
    hdcId: number;
    from: number;
    to: number;
  }): Promise<APIResult<SchedulingEvent[]>> {
    return this.makeAxiosJSONRequest(
      "GET",
      `${this.route}/${params.sId}/production-units/distribution-circuits/${params.hdcId}/scheduling/events`,
      {
        hdcId: params.hdcId,
        from: params.from,
        to: params.to,
      },
    );
  }

  fetchSchedulingMode(params: {
    sId: string;
    hdcId: string;
  }): Promise<APIResult<{ mode: string }>> {
    return this.makeAxiosJSONRequest(
      "GET",
      `${this.route}/${params.sId}/production-units/distribution-circuits/${params.hdcId}/scheduling/mode`,
    );
  }

  fetchSchedulingParameters(params: {
    sId: string;
    hdcId: string;
  }): Promise<APIResult<SchedulingParameters>> {
    return this.makeAxiosJSONRequest(
      "GET",
      `${this.route}/${params.sId}/production-units/distribution-circuits/${params.hdcId}/scheduling/parameters`,
    );
  }

  downloadRegulationMeasurements(params: {
    sId: string;
    hic: string;
    from: number;
    to: number;
    fileName: string;
  }): Promise<void> {
    return this.makeAxiosCSVDownloadRequest(
      "GET",
      `${this.route}/${params.sId}/production-units/distribution-circuits/${params.hic}/regulation-measurements`,
      { from: params.from, to: params.to, fileName: params.fileName },
    );
  }

  updateHeatingDistributionCircuitRegulationParameters(params: {
    slug: string;
    hicId: string;
    regulationParams: RegulationParameters;
  }): Promise<APIResult<{ params: SchedulingParameters }>> {
    return this.makeAxiosJSONRequest(
      "PUT",
      `${this.route}/${params.slug}/production-units/distribution-circuits/${params.hicId}/regulation/parameters`,
      {
        targetTemperature: params.regulationParams.targetTemperature,
        noHeatingTemperature: params.regulationParams.noHeatingTemperature,
        heatingTimeConstant: params.regulationParams.airParameters.heatingTimeConstant,
        coolingTimeConstant: params.regulationParams.airParameters.coolingTimeConstant,
        heatingLawSlope: params.regulationParams.heatingLawSlope,
        temperatureThreshold1: params.regulationParams.slopeParameters.temperatureThreshold1,
        alphaThreshold1: params.regulationParams.slopeParameters.alphaThreshold1,
        temperatureThreshold2: params.regulationParams.slopeParameters.temperatureThreshold2,
        alphaThreshold2: params.regulationParams.slopeParameters.alphaThreshold2,
        solarNormalisation: params.regulationParams.solarParameters.solarNormalisation,
        solarWeight: params.regulationParams.solarParameters.solarWeight,
        solarMaxTemperature: params.regulationParams.solarParameters.solarMaxTemperature,
        solarMeanTemperature: params.regulationParams.solarParameters.solarMeanTemperature,
        offsetUpperLimit: params.regulationParams.offsetUpperLimit,
        offsetLowerLimit: params.regulationParams.offsetLowerLimit,
        customOffset: params.regulationParams.customOffset,
        regulationType: params.regulationParams.regulationType,
      },
    );
  }

  saveHeatingDistributionCircuitRegulationParameters(params: {
    slug: string;
    hicId: string;
    regulationParams: RegulationParameters;
  }): Promise<APIResult<{ params: SchedulingParameters }>> {
    return this.makeAxiosJSONRequest(
      "POST",
      `${this.route}/${params.slug}/production-units/distribution-circuits/${params.hicId}/regulation/parameters`,
      {
        targetTemperature: params.regulationParams.targetTemperature,
        noHeatingTemperature: params.regulationParams.noHeatingTemperature,
        heatingTimeConstant: params.regulationParams.airParameters.heatingTimeConstant,
        coolingTimeConstant: params.regulationParams.airParameters.coolingTimeConstant,
        heatingLawSlope: params.regulationParams.heatingLawSlope,
        temperatureThreshold1: params.regulationParams.slopeParameters.temperatureThreshold1,
        alphaThreshold1: params.regulationParams.slopeParameters.alphaThreshold1,
        temperatureThreshold2: params.regulationParams.slopeParameters.temperatureThreshold2,
        alphaThreshold2: params.regulationParams.slopeParameters.alphaThreshold2,
        solarNormalisation: params.regulationParams.solarParameters.solarNormalisation,
        solarWeight: params.regulationParams.solarParameters.solarWeight,
        solarMaxTemperature: params.regulationParams.solarParameters.solarMaxTemperature,
        solarMeanTemperature: params.regulationParams.solarParameters.solarMeanTemperature,
        offsetUpperLimit: params.regulationParams.offsetUpperLimit,
        offsetLowerLimit: params.regulationParams.offsetLowerLimit,
        customOffset: params.regulationParams.customOffset,
        regulationType: params.regulationParams.regulationType,
      },
    );
  }

  fetchDistributionCircuitDJUSavings(params: {
    slug: string;
    from: number;
    to: number;
    freq: "hourly" | "daily";
    dcIds: string[];
  }): Promise<APIResult<DJUSaving[]>> {
    return this.makeAxiosJSONRequest(
      "GET",
      `${this.route}/${params.slug}/production-units/distribution-circuits/regulation/dju-savings`,
      {
        from: params.from,
        to: params.to,
        freq: params.freq,
        dcid: params.dcIds,
      },
    );
  }
}

export class InstrumentAPIService extends APIService implements InstrumentService {
  private route = "/instruments";

  fetchOne(params: { iId: string }): Promise<APIResult<Instrument>> {
    return this.makeAxiosJSONRequest("GET", `${this.route}/${params.iId}`);
  }

  fetchMany(params: { iIds: string[] }): Promise<APIResult<Instrument[]>> {
    return this.makeAxiosJSONRequest("GET", `${this.route}`, { iid: params.iIds });
  }

  update(instrument: Instrument): Promise<APIResult<Instrument>> {
    return this.makeAxiosJSONRequest(
      "PUT",
      `${this.route}/${instrument.id}`,
      {},
      {
        siteId: instrument.siteId,
        name: instrument.name,
        comments: instrument.comments,
        category: instrument.category,
      },
    );
  }

  updateComment(instrument: Instrument): Promise<APIResult<Instrument>> {
    return this.makeAxiosJSONRequest(
      "PUT",
      `${this.route}/update-comment/${instrument.id}`,
      {},
      {
        siteId: instrument.siteId,
        comments: instrument.comments,
      },
    );
  }

  updateDisposition(
    disposition: InstrumentDisposition,
    iid: string,
  ): Promise<APIResult<Instrument>> {
    return this.makeAxiosJSONRequest(
      "PUT",
      `${this.route}/update-disposition/${iid}`,
      {},
      disposition,
    );
  }

  save(instrument: Omit<Instrument, "id" | "battery">): Promise<APIResult<Instrument>> {
    return this.makeAxiosJSONRequest("POST", `${this.route}`, {}, instrument);
  }

  updateCategory(iids: number[], category: InstrumentCategory): Promise<APIResult<void>> {
    return this.makeAxiosJSONRequest("PUT", `${this.route}/update-category/${category}`, {
      iid: iids,
    });
  }

  delete(params: { iId: string }): Promise<APIResult<{}>> {
    return this.makeAxiosJSONRequest("DELETE", `${this.route}/${params.iId}`);
  }

  deleteMeasurements(params: { iId: number; from: number; to: number }): Promise<APIResult<{}>> {
    return this.makeAxiosJSONRequest("DELETE", `${this.route}/${params.iId}/measurements`, {
      from: params.from,
      to: params.to,
    });
  }

  fetchInstallationSessions(): Promise<APIResult<InstrumentDeploymentSession[]>> {
    return this.makeAxiosJSONRequest("GET", `${this.route}/installation-sessions`);
  }

  fetchOneInstallationSession(dsid: string): Promise<APIResult<InstrumentDeploymentSession>> {
    return this.makeAxiosJSONRequest("GET", `${this.route}/installation-sessions/${dsid}`);
  }

  fetchMappingSessions(): Promise<APIResult<MappingSession[]>> {
    return this.makeAxiosJSONRequest("GET", `${this.route}/mapping-session`);
  }

  createMappingSession(params: { name: string }): Promise<APIResult<void>> {
    return this.makeAxiosJSONRequest("POST", `${this.route}/mapping-session`, {}, params);
  }

  createInstallationSession(params: {
    name: string;
    slug: string;
    supplierId: number;
    mappingSessionId: number;
  }): Promise<APIResult<void>> {
    return this.makeAxiosJSONRequest("POST", `${this.route}/installation-sessions`, {}, params);
  }

  updateInstallationSessionMapping(params: {
    dsid: string;
    msid: number;
  }): Promise<APIResult<void>> {
    return this.makeAxiosJSONRequest(
      "PUT",
      `${this.route}/installation-sessions/${params.dsid}/mapping-session/${params.msid}`,
    );
  }

  updateInstallationSessionStatus(dsid: string): Promise<APIResult<void>> {
    return this.makeAxiosJSONRequest("PUT", `${this.route}/installation-sessions/${dsid}/status`);
  }

  fetchAllStock(): Promise<APIResult<InstrumentStock[]>> {
    return this.makeAxiosJSONRequest("GET", `${this.route}/installation-sessions/stock-instrument`);
  }

  fetchSiteInstrumentStock(slug: string): Promise<APIResult<InstrumentStock[]>> {
    return this.makeAxiosJSONRequest(
      "GET",
      `${this.route}/installation-sessions/stock-instrument/site/${slug}`,
    );
  }

  fetchInstrumentStock(dsid: string): Promise<APIResult<InstrumentStock[]>> {
    return this.makeAxiosJSONRequest(
      "GET",
      `${this.route}/installation-sessions/stock-instrument/${dsid}`,
    );
  }

  createStockInstrument(params: InstrumentStockCreate): Promise<APIResult<string>> {
    return this.makeAxiosJSONRequest(
      "POST",
      `${this.route}/installation-sessions/stock-instrument`,
      {},
      params,
    );
  }

  updateStockInstrument(params: InstrumentStock): Promise<APIResult<void>> {
    return this.makeAxiosJSONRequest(
      "PUT",
      `${this.route}/installation-sessions/stock-instrument`,
      {},
      params,
    );
  }

  deleteStockInstrument(sn: string): Promise<APIResult<void>> {
    return this.makeAxiosJSONRequest(
      "DELETE",
      `${this.route}/installation-sessions/stock-instrument/${sn}`,
    );
  }

  downloadRequeaProviderFile(dsId: string, fileName: string): Promise<void> {
    return this.makeAxiosXLSXDownloadRequest(
      "GET",
      `${this.route}/installation-sessions/${dsId}/requea-provider-file`,
      { fileName: fileName },
    );
  }

  downloadLiveObjectProviderFile(dsId: string, fileName: string): Promise<void> {
    return this.makeAxiosCSVDownloadRequest(
      "GET",
      `${this.route}/installation-sessions/${dsId}/liveobject-provider-file`,
      { fileName: fileName },
    );
  }

  getRequeaLastUptime(sns: string[]): Promise<APIResult<{ [key: string]: string }>> {
    return this.makeAxiosJSONRequest("GET", `/requea/last-uptime`, { sn: sns });
  }

  latestMeasurements(params: {
    iIds: string[];
  }): Promise<APIResult<{ [key: string]: Measurement }>> {
    return this.makeAxiosJSONRequest("GET", `${this.route}/measurements/latest`, {
      iids: params.iIds,
    });
  }
}

export interface ProvidersService {
  getRequeaLastUptime(params: { isns: string[] }): Promise<APIResult<{ [key: string]: string }>>;
  getRequeaGateways(): Promise<APIResult<RequeaGateway[]>>;
}

export class ProvidersAPIService extends APIService implements ProvidersService {
  private route = "/providers";

  getRequeaLastUptime(params: { isns: string[] }): Promise<APIResult<{ [key: string]: string }>> {
    return this.makeAxiosJSONRequest("GET", `${this.route}/requea/last-uptime`, {
      isn: params.isns,
    });
  }

  getRequeaGateways(): Promise<APIResult<RequeaGateway[]>> {
    return this.makeAxiosJSONRequest("GET", `${this.route}/requea/gateways`);
  }
}

export class InstrumentGroupAPIService extends APIService implements InstrumentGroupService {
  private route = "/instrument-groups";

  fetchOne(params: { igId: string }): Promise<APIResult<InstrumentGroup>> {
    return this.makeAxiosJSONRequest("GET", `${this.route}/${params.igId}`);
  }

  fetchMany(params: { igIds: string[] }): Promise<APIResult<InstrumentGroup[]>> {
    return this.makeAxiosJSONRequest("GET", `${this.route}`, { igid: params.igIds });
  }

  save(instrumentGroup: InstrumentGroupCreate): Promise<APIResult<InstrumentGroup>> {
    return this.makeAxiosJSONRequest("POST", this.route, {}, instrumentGroup);
  }

  delete(params: { igId: string }): Promise<APIResult<{}>> {
    return this.makeAxiosJSONRequest("DELETE", `${this.route}/${params.igId}`);
  }
}

export class InstrumentGroupMeasurementsAPIService
  extends APIService
  implements InstrumentGroupMeasurementService
{
  private route = "/instrument-groups";

  fetch(params: { igIds: string[]; from: number; to: number }): Promise<APIResult<Measurement[]>> {
    return this.makeAxiosJSONRequest("GET", `${this.route}/measurements`, {
      igid: params.igIds,
      from: params.from,
      to: params.to,
    });
  }

  fetchReference(params: {
    igIds: string[];
    from: number;
    to: number;
  }): Promise<APIResult<InstrumentGroupMeasurement[]>> {
    return this.makeAxiosJSONRequest("GET", `${this.route}/reference-measurements`, {
      igid: params.igIds,
      from: params.from,
      to: params.to,
    });
  }
}

export class InstrumentGroupAlertAPIService
  extends APIService
  implements InstrumentGroupAlertService
{
  private route = "/instrument-groups/alerts";

  fetchMany(): Promise<APIResult<InstrumentGroupAlert[]>> {
    return this.makeAxiosJSONRequest("GET", `${this.route}`);
  }

  create(alert: InstrumentGroupAlertCreate): Promise<APIResult<InstrumentGroupAlert>> {
    return this.makeAxiosJSONRequest("POST", this.route, {}, alert);
  }

  update(alert: InstrumentGroupAlert): Promise<APIResult<InstrumentGroupAlert>> {
    return this.makeAxiosJSONRequest("PUT", `${this.route}/${alert.id}`, {}, alert);
  }

  delete(params: { igaId: string }): Promise<APIResult<{}>> {
    return this.makeAxiosJSONRequest("DELETE", `${this.route}/${params.igaId}`);
  }

  instrumentGroupsAcknowledgeAlert(aid: number) {
    return this.makeAxiosJSONRequest("PUT", `${this.route}/acknowledge/${aid}`);
  }
}

export class MeasurementAPIService extends APIService implements MeasurementService {
  private route = "/instruments/measurements";

  fetch(params: { iIds: string[]; from: number; to: number }): Promise<APIResult<Measurement[]>> {
    return this.makeAxiosJSONRequest("GET", this.route, {
      iid: params.iIds,
      from: params.from,
      to: params.to,
    });
  }

  download(params: { iId: string; from: number; to: number; fileName: string }): Promise<void> {
    return this.makeAxiosCSVDownloadRequest("GET", `/instruments/measurements`, {
      iid: params.iId,
      from: params.from,
      to: params.to,
      fileName: params.fileName,
    });
  }
}

export class SiteAPIService extends APIService implements SiteService {
  private route = "/sites";

  fetch(): Promise<APIResult<Site[]>> {
    return this.makeAxiosJSONRequest("GET", this.route);
  }

  getSiteBuildingImages(params: { slug: string }): Promise<APIResult<SiteImage[]>> {
    return this.makeAxiosJSONRequest("GET", `${this.route}/${params.slug}/building-images`);
  }

  getImageInstrumentPositions(params: {
    sId: string;
    imageIds: number[];
  }): Promise<APIResult<ImageInstrumentPosition[]>> {
    return this.makeAxiosJSONRequest(
      "GET",
      `${this.route}/${params.sId}/image/instrument-position`,
      {
        imageId: params.imageIds,
      },
    );
  }

  saveImageInstrumentPositions(params: {
    sId: string;
    iips: ImageInstrumentPosition[];
  }): Promise<APIResult<void>> {
    return this.makeAxiosJSONRequest(
      "PUT",
      `${this.route}/${params.sId}/image/instrument-position`,
      {},
      params.iips,
    );
  }

  deleteImageInstrumentPositions(params: {
    sId: string;
    iips: ImageInstrumentPosition[];
  }): Promise<APIResult<void>> {
    return this.makeAxiosJSONRequest(
      "DELETE",
      `${this.route}/${params.sId}/image/instrument-position`,
      {},
      params.iips,
    );
  }

  getImageInstrumentGroupPositions(params: {
    sId: string;
    imageIds: number[];
  }): Promise<APIResult<ImageInstrumentGroupPosition[]>> {
    return this.makeAxiosJSONRequest(
      "GET",
      `${this.route}/${params.sId}/image/instrument-group-position`,
      {
        imageId: params.imageIds,
      },
    );
  }

  saveImageInstrumentGroupPositions(params: {
    sId: string;
    iigps: ImageInstrumentGroupPosition[];
  }): Promise<APIResult<void>> {
    return this.makeAxiosJSONRequest(
      "PUT",
      `${this.route}/${params.sId}/image/instrument-group-position`,
      {},
      params.iigps,
    );
  }

  deleteImageInstrumentGroupPositions(params: {
    sId: string;
    iigps: ImageInstrumentGroupPosition[];
  }): Promise<APIResult<void>> {
    return this.makeAxiosJSONRequest(
      "DELETE",
      `${this.route}/${params.sId}/image/instrument-group-position`,
      {},
      params.iigps,
    );
  }

  fetchOne(params: { sId: string }): Promise<APIResult<Site>> {
    return this.makeAxiosJSONRequest("GET", `${this.route}/${params.sId}`);
  }

  fetchEnergyMeters(params: { sId: string }): Promise<APIResult<EnergyMeter[]>> {
    return this.makeAxiosJSONRequest("GET", `${this.route}/${params.sId}/energy-meters`);
  }

  fetchInstruments(params: {
    sId: string;
    supplierIds?: number[];
  }): Promise<APIResult<Instrument[]>> {
    return this.makeAxiosJSONRequest("GET", `${this.route}/${params.sId}/instruments`, {
      supplierId: params.supplierIds,
    });
  }

  fetchEnergyRegressionParams(params: {
    slug: string;
    from: number;
    to: number;
  }): Promise<APIResult<EnergyRegressionParams>> {
    return this.makeAxiosJSONRequest(
      "GET",
      `${this.route}/${params.slug}/energy-regression-params`,
      {
        from: params.from,
        to: params.to,
      },
    );
  }

  fetchReferenceInstrumentGroups(params: { sId: string }): Promise<APIResult<InstrumentGroup[]>> {
    return this.makeAxiosJSONRequest(
      "GET",
      `${this.route}/${params.sId}/instrument-groups/reference`,
    );
  }

  fetchInstrumentGroups(params: { sId: string }): Promise<APIResult<InstrumentGroup[]>> {
    return this.makeAxiosJSONRequest("GET", `${this.route}/${params.sId}/instrument-groups`);
  }

  fetchEnergySaving(params: {
    slug: string;
    from: number;
    to: number;
  }): Promise<APIResult<EnergySaving>> {
    return this.makeAxiosJSONRequest("GET", `${this.route}/${params.slug}/energy-saving`, {
      from: params.from,
      to: params.to,
    });
  }

  fetchSitePhysics(params: { slug: string }): Promise<APIResult<SitePhysics>> {
    return this.makeAxiosJSONRequest("GET", `${this.route}/${params.slug}/physics`);
  }

  saveSitePhysics(params: { slug: string; sp: SitePhysics }) {
    return this.makeAxiosJSONRequest("PUT", `${this.route}/${params.slug}/physics`, {}, params.sp);
  }

  fetchSiteLogbook(params: { slug: string }): Promise<APIResult<SiteLogbook[]>> {
    return this.makeAxiosJSONRequest("GET", `${this.route}/${params.slug}/logbook`);
  }

  saveSiteLogbook(params: { slug: string; logbook: SiteLogbookInput }) {
    return this.makeAxiosJSONRequest(
      "POST",
      `${this.route}/${params.slug}/logbook`,
      {},
      params.logbook,
    );
  }

  fetchHeatingSeasons(params: { sId: string }): Promise<APIResult<HeatingSeason[]>> {
    return this.makeAxiosJSONRequest("GET", `${this.route}/${params.sId}/heating-seasons`);
  }

  latestMeasurements(params: { sId: string }): Promise<APIResult<{ [k: string]: Measurement }>> {
    return this.makeAxiosJSONRequest(
      "GET",
      `${this.route}/${params.sId}/instruments/measurements/latest`,
    );
  }

  periodMeasurements(params: {
    sId: string;
    from: number;
    to: number;
  }): Promise<APIResult<{ [k: string]: AggregatedMeasurement }>> {
    return this.makeAxiosJSONRequest(
      "GET",
      `${this.route}/${params.sId}/instruments/measurements/period`,
      { from: params.from, to: params.to },
    );
  }

  downloadSiteMeasurements(params: {
    sId: string;
    from: number;
    to: number;
    fileName: string;
  }): Promise<void> {
    return this.makeAxiosCSVDownloadRequest(
      "GET",
      `${this.route}/${params.sId}/instruments/export-all`,
      { from: params.from, to: params.to, fileName: params.fileName },
    );
  }

  downloadSiteConsumption(params: {
    sId: string;
    fileName: string;
    from: number;
    to: number;
  }): Promise<void> {
    return this.downloadYearConsumptionCSV(
      "GET",
      `${this.route}/${params.sId}/energy-meters/consumption`,
      { fileName: params.fileName, from: params.from, to: params.to },
    );
  }

  downloadSiteReport(params: ReportFormData & { sId: string }): Promise<void> {
    return this.makeAxiosPDFDownloadRequest("GET", `${this.route}/${params.sId}/report`, {
      from: params.startDate.unix(),
      to: params.endDate.unix(),
      fromRef: params.startDateRef?.unix(),
      toRef: params.endDateRef?.unix(),
      selectedGroupIds: params.selectedGroupIds,
      selectedEnergyMeterIds: params.selectedEnergyMeterIds,
      selectedPages: params.selectedPages,
      directorParagraph: params.directorParagraph,
    });
  }

  downloadInstrumentsMeasurements(params: {
    fileName: string;
    instrumentIds: number[];
    groupIds: number[];
    withWeather: boolean;
    from: number;
    to: number;
    sId: string;
  }): Promise<void> {
    return this.makeAxiosInstrumentsCSVDownloadRequest(
      "GET",
      `${this.route}/${params.sId}/instruments/export`,
      {
        fileName: params.fileName,
        iIds: params.instrumentIds,
        gIds: params.groupIds,
        withWeather: params.withWeather,
        from: params.from,
        to: params.to,
      },
    );
  }

  create(site: SiteCreate): Promise<APIResult<Site>> {
    return this.makeAxiosJSONRequest("POST", this.route, {}, site);
  }

  update(site: Partial<Site>, slug: string): Promise<APIResult<Site>> {
    return this.makeAxiosJSONRequest("PUT", this.route + "/" + slug, {}, site);
  }

  updateHeatingSeason(params: {
    sId: string;
    heatingSeason: Partial<HeatingSeason>;
  }): Promise<APIResult<HeatingSeason>> {
    return this.makeAxiosJSONRequest(
      "PUT",
      `${this.route}/${params.sId}/heating-season`,
      {},
      params.heatingSeason,
    );
  }

  setHeatingSeasonReference(params: { slug: string; hsid: number }): Promise<APIResult<any>> {
    return this.makeAxiosJSONRequest(
      "PUT",
      `${this.route}/${params.slug}/heating-season/${params.hsid}/reference`,
    );
  }

  fetchHeatingProductionUnit(params: { sId: string }): Promise<APIResult<HeatingProductionUnit[]>> {
    return this.makeAxiosJSONRequest("GET", `${this.route}/${params.sId}/production-units`);
  }

  fetchProductionDataPoints(params: {
    sId: string;
    hpuId: number;
  }): Promise<APIResult<ProductionDataPoint[]>> {
    return this.makeAxiosJSONRequest(
      "GET",
      `${this.route}/${params.sId}/production-units/${params.hpuId}/data-points`,
    );
  }

  saveProductionDataPoint(params: {
    sId: string;
    hpuId: number;
    pdp: ProductionDataPoint;
  }): Promise<APIResult<number>> {
    return this.makeAxiosJSONRequest(
      "POST",
      `${this.route}/${params.sId}/production-units/${params.hpuId}/data-points`,
      {},
      params.pdp,
    );
  }

  updateProductionDataPoint(params: {
    sId: string;
    hpuId: number;
    pdp: Partial<ProductionDataPoint & { id: number }>;
  }): Promise<APIResult<number>> {
    return this.makeAxiosJSONRequest(
      "PUT",
      `${this.route}/${params.sId}/production-units/${params.hpuId}/data-points/${params.pdp.id}`,
      {},
      params.pdp,
    );
  }

  deleteProductionDataPoint(params: {
    sId: string;
    hpuId: number;
    pdpId: number;
  }): Promise<APIResult<any>> {
    return this.makeAxiosJSONRequest(
      "DELETE",
      `${this.route}/${params.sId}/production-units/${params.hpuId}/data-points/${params.pdpId}`,
    );
  }

  fetchDistributionDataPoints(params: {
    sId: string;
    hdcId: number;
  }): Promise<APIResult<DistributionDataPoint[]>> {
    return this.makeAxiosJSONRequest(
      "GET",
      `${this.route}/${params.sId}/production-units/distribution-circuits/${params.hdcId}/data-points`,
    );
  }

  saveDistributionDataPoint(params: {
    sId: string;
    hdcId: number;
    ddp: DistributionDataPoint;
  }): Promise<APIResult<number>> {
    return this.makeAxiosJSONRequest(
      "POST",
      `${this.route}/${params.sId}/production-units/distribution-circuits/${params.hdcId}/data-points`,
      {},
      params.ddp,
    );
  }

  updateDistributionDataPoint(params: {
    sId: string;
    hdcId: number;
    ddp: Partial<DistributionDataPoint & { id: number }>;
  }): Promise<APIResult<number>> {
    return this.makeAxiosJSONRequest(
      "PUT",
      `${this.route}/${params.sId}/production-units/distribution-circuits/${params.hdcId}/data-points/${params.ddp.id}`,
      {},
      params.ddp,
    );
  }

  deleteDistributionDataPoint(params: {
    sId: string;
    hdcId: number;
    ddpId: number;
  }): Promise<APIResult<any>> {
    return this.makeAxiosJSONRequest(
      "DELETE",
      `${this.route}/${params.sId}/production-units/distribution-circuits/${params.hdcId}/data-points/${params.ddpId}`,
    );
  }

  fetchSitePlcs(params: { sId: string }): Promise<APIResult<PLC[]>> {
    return this.makeAxiosJSONRequest("GET", `${this.route}/${params.sId}/plcs`);
  }

  saveSitePlc(params: { sId: string; plc: PLC }): Promise<APIResult<number>> {
    return this.makeAxiosJSONRequest("POST", `${this.route}/${params.sId}/plc`, {}, params.plc);
  }

  updateSitePlc(params: {
    sId: string;
    plc: Partial<PLC> & { id: number };
  }): Promise<APIResult<number>> {
    return this.makeAxiosJSONRequest(
      "PUT",
      `${this.route}/${params.sId}/plc/${params.plc.id}`,
      {},
      params.plc,
    );
  }

  saveSiteHeatingProductionUnit(params: {
    sId: string;
    heatingProductionUnit: HeatingProductionUnit;
  }): Promise<APIResult<number>> {
    return this.makeAxiosJSONRequest(
      "POST",
      `${this.route}/${params.sId}/production-units`,
      {},
      params.heatingProductionUnit,
    );
  }

  updateSiteHeatingProductionUnit(params: {
    sId: string;
    heatingProductionUnit: Partial<HeatingProductionUnit> & { id: number };
  }): Promise<APIResult<number>> {
    return this.makeAxiosJSONRequest(
      "PUT",
      `${this.route}/${params.sId}/production-units/${params.heatingProductionUnit.id}`,
      {},
      params.heatingProductionUnit,
    );
  }

  saveSiteHeatingDistributionCircuit(params: {
    sId: string;
    heatingDistributionCircuit: HeatingDistributionCircuit;
  }): Promise<APIResult<number>> {
    return this.makeAxiosJSONRequest(
      "POST",
      `${this.route}/${params.sId}/production-units/distribution-circuits`,
      {},
      params.heatingDistributionCircuit,
    );
  }

  updateSiteHeatingDistributionCircuit(params: {
    sId: string;
    heatingDistributionCircuit: Partial<HeatingDistributionCircuit> & { id: number };
  }): Promise<APIResult<number>> {
    return this.makeAxiosJSONRequest(
      "PUT",
      `${this.route}/${params.sId}/production-units/distribution-circuits/${params.heatingDistributionCircuit.id}`,
      {},
      params.heatingDistributionCircuit,
    );
  }

  saveSiteEnergyMeter(params: { sId: string; em: EnergyMeter }): Promise<APIResult<number>> {
    return this.makeAxiosJSONRequest(
      "POST",
      `${this.route}/${params.sId}/energy-meters`,
      {},
      params.em,
    );
  }

  updateSiteEnergyMeter(params: {
    sId: string;
    em: Partial<EnergyMeter> & { id: number };
  }): Promise<APIResult<number>> {
    return this.makeAxiosJSONRequest(
      "PUT",
      `${this.route}/${params.sId}/energy-meters/${params.em.id}`,
      {},
      params.em,
    );
  }

  fetchHeatingProductionUnitMeasurements(params: {
    sId: string;
    hpuId: number;
    from: number;
    to?: number;
  }): Promise<APIResult<HeatingProductionUnitMeasurement[]>> {
    const { sId, hpuId, from, to } = params;
    return this.makeAxiosJSONRequest(
      "GET",
      `${this.route}/${sId}/production-units/${hpuId}/measurements`,
      { from, to },
    );
  }

  fetchHeatingDistributionCircuits(params: {
    sId: string;
  }): Promise<APIResult<HeatingDistributionCircuit[]>> {
    return this.makeAxiosJSONRequest(
      "GET",
      `${this.route}/${params.sId}/production-units/distribution-circuits`,
    );
  }

  fetchHeatingDistributionMeasurements(params: {
    sId: string;
    hdcId: number;
    from: number;
    to?: number;
  }): Promise<APIResult<HeatingDistributionCircuitMeasurement[]>> {
    const { sId, hdcId, from, to } = params;
    return this.makeAxiosJSONRequest(
      "GET",
      `${this.route}/${sId}/production-units/distribution-circuits/${hdcId}/measurements`,
      { from, to },
    );
  }

  updateHeatingDistributionCircuitInstrumentGroupId(params: {
    sId: string;
    hdcId: number;
    instrumentGroupId: number | null;
  }): Promise<APIResult<number>> {
    const { sId, hdcId, instrumentGroupId } = params;
    return this.makeAxiosJSONRequest(
      "PUT",
      `${this.route}/${sId}/production-units/distribution-circuits/${hdcId}/instrument-group`,
      {},
      { instrumentGroupId },
    );
  }

  fetchHeatingDistributionCurve(params: {
    sId: string;
    hdcId: number;
  }): Promise<APIResult<HeatingDistributionCircuitCurve[]>> {
    return this.makeAxiosJSONRequest(
      "GET",
      `${this.route}/${params.sId}/production-units/distribution-circuits/${params.hdcId}/heating-curve`,
    );
  }

  fetchHeatingDistributionCurveHistory(params: {
    sId: string;
    hicId: string;
  }): Promise<APIResult<HeatingCurveHistory[]>> {
    return this.makeAxiosJSONRequest(
      "GET",
      `${this.route}/${params.sId}/production-units/distribution-circuits/${params.hicId}/heating-curve-history`,
    );
  }

  fetchHeatingDistributionCircuitPredictiveSystem(params: {
    sId: string;
    hicId: string;
  }): Promise<APIResult<HeatingDistributionCircuitPredictiveSystem>> {
    return this.makeAxiosJSONRequest(
      "GET",
      `${this.route}/${params.sId}/production-units/distribution-circuits/${params.hicId}/predictive-system`,
    );
  }

  toggleHeatingDistributionCircuitPredictiveSystem(params: {
    sId: string;
    hicId: string;
  }): Promise<APIResult<HeatingDistributionCircuitPredictiveSystem>> {
    return this.makeAxiosJSONRequest(
      "POST",
      `${this.route}/${params.sId}/production-units/distribution-circuits/${params.hicId}/predictive-system/toggle`,
    );
  }

  updateHeatingDistributionCircuitSchedulingMode(params: {
    slug: string;
    hicId: string;
    mode: SchedulingMode;
  }): Promise<APIResult<{ mode: SchedulingMode }>> {
    return this.makeAxiosJSONRequest(
      "POST",
      `${this.route}/${params.slug}/production-units/distribution-circuits/${params.hicId}/scheduling/${params.mode}`,
    );
  }

  updateHeatingDistributionCircuitSchedulingParameters(params: {
    slug: string;
    hicId: string;
    schedulingParams: SchedulingParametersInput;
  }): Promise<APIResult<{ params: SchedulingParameters }>> {
    return this.makeAxiosJSONRequest(
      "POST",
      `${this.route}/${params.slug}/production-units/distribution-circuits/${params.hicId}/scheduling/parameters`,
      {
        preHeatingMinutes: params.schedulingParams.preHeatingMinutes,
        coolingInertia: params.schedulingParams.coolingInertia,
        heatingRate: params.schedulingParams.heatingRate,
      },
    );
  }

  fetchHeatingDistributionCircuitWatchdogState(params: {
    sId: string;
    hdcId: string;
  }): Promise<APIResult<HeatingDistributionCircuitWatchdogState>> {
    return this.makeAxiosJSONRequest(
      "GET",
      `${this.route}/${params.sId}/production-units/distribution-circuits/${params.hdcId}/watchdog-state`,
    );
  }

  fetchHeatingDistributionCircuitHeatingCurveState(params: {
    sId: string;
    hdcId: string;
  }): Promise<APIResult<HeatingDistributionCircuitHeatingCurveState>> {
    return this.makeAxiosJSONRequest(
      "GET",
      `${this.route}/${params.sId}/production-units/distribution-circuits/${params.hdcId}/heating-curve-state`,
    );
  }

  fetchHeatingDistributionCircuitV3VRegulation(params: {
    sId: string;
    hdcId: string;
  }): Promise<APIResult<boolean>> {
    return this.makeAxiosJSONRequest(
      "GET",
      `${this.route}/${params.sId}/production-units/distribution-circuits/${params.hdcId}/v3v-regulation`,
    );
  }

  updateCircuitHeatingCurveParameters(params: {
    sId: string;
    hdcId: string;
    hcs: HeatingDistributionCircuitHeatingCurveState;
  }): Promise<APIResult<{ isWatchdogActive: boolean }>> {
    console.log(params.hcs);
    return this.makeAxiosJSONRequest(
      "PUT",
      `${this.route}/${params.sId}/production-units/distribution-circuits/${params.hdcId}/heating-curve/parameters`,
      {},
      params.hcs,
    );
  }

  togglePlcWatchdogState(params: {
    sId: string;
    plcId: string;
  }): Promise<APIResult<{ isWatchdogActive: boolean }>> {
    return this.makeAxiosJSONRequest(
      "POST",
      `${this.route}/${params.sId}/plcs/${params.plcId}/toggle-watchdog`,
    );
  }

  uploadImage(params: {
    msId: number | null;
    slug: string;
    file: File;
    fileUID: string;
    fileName: string;
  }): Promise<APIResult<string>> {
    return this.makeAxiosMultiformDataRequest(
      "POST",
      `${this.route}/${params.slug}/building-image`,
      { fileUID: params.fileUID, fileName: params.fileName, msId: params.msId },
      params.file,
    );
  }

  deleteImage(params: { fileUID: string; slug: string }): Promise<APIResult<void>> {
    return this.makeAxiosJSONRequest("DELETE", `${this.route}/${params.slug}/building-image`, {
      fileUID: params.fileUID,
    });
  }

  getMappingSessionImages(params: { msId: number; slug: string }): Promise<APIResult<SiteImage[]>> {
    return this.makeAxiosJSONRequest(
      "GET",
      `${this.route}/${params.slug}/mapping-session/${params.msId}/building-images`,
    );
  }

  fetchMonthlyIndicator(
    slug: string,
    from: number,
    to: number,
  ): Promise<APIResult<MonthlyIndicator[]>> {
    return this.makeAxiosJSONRequest("GET", `${this.route}/${slug}/monthly-indicator`, {
      from,
      to,
    });
  }
}

export class UserManagementAPIService extends APIService implements UserManagementService {
  private route = "/user-management/users";

  fetch(): Promise<APIResult<UserManagementUser[]>> {
    return this.makeAxiosJSONRequest("GET", this.route);
  }

  fetchRequiredActions(): Promise<APIResult<UserRequiredActions>> {
    return this.makeAxiosJSONRequest("GET", this.route + "/required-actions");
  }

  fetchRoles(): Promise<APIResult<Role[]>> {
    return this.makeAxiosJSONRequest("GET", this.route + "/roles");
  }

  fetchUserRoles(userId: string): Promise<APIResult<Role[]>> {
    return this.makeAxiosJSONRequest("GET", this.route + "/role/" + userId);
  }

  create(user: UserManagementUserCreate): Promise<APIResult<UserManagementUser>> {
    return this.makeAxiosJSONRequest("POST", this.route, {}, user);
  }

  update(user: UserManagementUserUpdate): Promise<APIResult<UserManagementUser>> {
    return this.makeAxiosJSONRequest("PUT", `${this.route}/${user.id}`, {}, user);
  }

  downloadUsers(params: { fileName: string }): Promise<void> {
    return this.makeAxiosCSVDownloadRequest("GET", this.route, {
      fileName: params.fileName,
    });
  }

  delete(params: { uId: string }): Promise<APIResult<{}>> {
    return this.makeAxiosJSONRequest("DELETE", `${this.route}/${params.uId}`);
  }
}

export class WeatherAPIService extends APIService implements WeatherService {
  private route = "/weather";

  fetchWeatherData(params: {
    sid: number;
    from: number;
    to: number;
  }): Promise<APIResult<WeatherData>> {
    const { sid, from, to } = params;
    return this.makeAxiosJSONRequest("GET", `${this.route}/${sid}`, {
      from,
      to,
    });
  }

  fetchWeatherLocations(): Promise<APIResult<WeatherLocation[]>> {
    return this.makeAxiosJSONRequest("GET", this.route + "/locations");
  }
}

export interface HealthZService {
  fetch(): Promise<APIResult<any>>;
}

export class HealthZAPIService extends APIService implements HealthZService {
  private route = "/healthz";

  fetch(): Promise<APIResult<any>> {
    return this.makeAxiosJSONRequest("GET", `${this.route}`);
  }
}

export interface MonitoringService {
  fetchPlcsMonitoring(): Promise<APIResult<PlcJobMonitoring[]>>;

  getPlcHealthz(plcid: number): Promise<APIResult<PlcHealthStatus>>;

  fetchJobAlerts(): Promise<APIResult<JobAlerts>>;

  fetchHdcJobMonitoring(): Promise<APIResult<HeatingDistributionCircuitJobMonitoring[]>>;

  fetchPlcsWithJobsConfiguration(): Promise<APIResult<PlcWithJobConfiguration[]>>;

  fetchHpusWithJobsConfiguration(): Promise<APIResult<HpuWithJobConfiguration[]>>;

  fetchEnergyMetersJobsMonitoring(): Promise<APIResult<EnergyMeterJobMonitoring[]>>;

  fetchEnergyMetersWithJobsConfiguration(): Promise<APIResult<EnergyMeterWithJobConfiguration[]>>;

  updateDistributionCircuitJobsConfiguration(
    configuration: JobConfiguration,
  ): Promise<APIResult<number>>;

  updatePlcJobConfiguration(params: {
    jobConfiguration: JobConfiguration;
  }): Promise<APIResult<number>>;

  updateHpuJobConfiguration(params: {
    jobConfiguration: JobConfiguration;
  }): Promise<APIResult<number>>;

  fetchInstrumentStats(): Promise<APIResult<InstrumentStatsResponse>>;

  updateEnergyMetersJobConfiguration(params: {
    jobConfiguration: JobConfiguration;
  }): Promise<APIResult<number>>;
}

export interface PLCJobsActivityStatus {
  health_check: boolean;
  watchdog_update: boolean;
}

export interface PlcHealthStatus {
  plcId: number;
  timestamp: string;
  isNetwork: boolean;
  isAuthenticated: boolean;
  isWatchdogUpdateActive: boolean;
}

export class MonitoringApiService extends APIService implements MonitoringService {
  private route = "/monitoring";

  fetchPlcsMonitoring(): Promise<APIResult<PlcJobMonitoring[]>> {
    return this.makeAxiosJSONRequest("GET", `${this.route}/plcs`);
  }

  fetchJobAlerts(): Promise<APIResult<JobAlerts>> {
    return this.makeAxiosJSONRequest("GET", `${this.route}/job-alerts`);
  }

  fetchHdcJobMonitoring(): Promise<APIResult<HeatingDistributionCircuitJobMonitoring[]>> {
    return this.makeAxiosJSONRequest("GET", `${this.route}/distribution-circuits/jobs`);
  }

  fetchHdcsWithJobsConfiguration(): Promise<APIResult<HdcWithJobConfiguration[]>> {
    return this.makeAxiosJSONRequest(
      "GET",
      `${this.route}/distribution-circuits/jobs/configuration`,
    );
  }

  fetchPlcsWithJobsConfiguration(): Promise<APIResult<PlcWithJobConfiguration[]>> {
    return this.makeAxiosJSONRequest("GET", `${this.route}/plcs/jobs/configuration`);
  }

  fetchPlcHealthzHistory(plcIds: number[]): Promise<APIResult<PlcHealthStatus[]>> {
    return this.makeAxiosJSONRequest("GET", `${this.route}/plcs/healthz/history`, {
      plcId: plcIds,
    });
  }

  fetchPredictiveMonitoring(): Promise<APIResult<PredictiveMonitoring[]>> {
    return this.makeAxiosJSONRequest(
      "GET",
      `${this.route}/distribution-circuits/predictive-monitoring`,
    );
  }

  fetchPredictiveMonitoringForCircuit(hdcId: number): Promise<APIResult<PredictiveMonitoring>> {
    return this.makeAxiosJSONRequest(
      "GET",
      `${this.route}/distribution-circuits/${hdcId}/predictive-monitoring`,
    );
  }

  fetchHpuJobsMonitoring(): Promise<APIResult<HpuJobMonitoring[]>> {
    return this.makeAxiosJSONRequest("GET", `${this.route}/production-units`);
  }

  fetchHpusWithJobsConfiguration(): Promise<APIResult<HpuWithJobConfiguration[]>> {
    return this.makeAxiosJSONRequest("GET", `${this.route}/production-units/jobs/configuration`);
  }

  fetchEnergyMetersJobsMonitoring(): Promise<APIResult<EnergyMeterJobMonitoring[]>> {
    return this.makeAxiosJSONRequest("GET", `${this.route}/energy-meters`);
  }

  fetchEnergyMetersWithJobsConfiguration(): Promise<APIResult<EnergyMeterWithJobConfiguration[]>> {
    return this.makeAxiosJSONRequest("GET", `${this.route}/energy-meters/jobs/configuration`);
  }

  getPlcHealthz(plcid: number): Promise<APIResult<PlcHealthStatus>> {
    return this.makeAxiosJSONRequest("GET", `${this.route}/plcs/${plcid}/healthz`);
  }

  updateDistributionCircuitJobsConfiguration(
    configuration: JobConfiguration,
  ): Promise<APIResult<number>> {
    return this.makeAxiosJSONRequest(
      "PUT",
      `${this.route}/distribution-circuits/configuration`,
      {},
      configuration,
    );
  }

  updatePlcJobConfiguration(params: {
    jobConfiguration: JobConfiguration;
  }): Promise<APIResult<number>> {
    return this.makeAxiosJSONRequest(
      "PUT",
      `${this.route}/plcs/jobs/configuration`,
      {},
      params.jobConfiguration,
    );
  }

  updateHpuJobConfiguration(params: {
    jobConfiguration: JobConfiguration;
  }): Promise<APIResult<number>> {
    return this.makeAxiosJSONRequest(
      "PUT",
      `${this.route}/production-units/jobs/configuration`,
      {},
      params.jobConfiguration,
    );
  }

  updateEnergyMetersJobConfiguration(params: {
    jobConfiguration: JobConfiguration;
  }): Promise<APIResult<number>> {
    return this.makeAxiosJSONRequest(
      "PUT",
      `${this.route}/energy-meters/jobs/configuration`,
      {},
      params.jobConfiguration,
    );
  }

  fetchSchedulingMeasurements(
    hdcId: number,
    from: number,
    to: number,
  ): Promise<APIResult<SchedulingMeasurement[]>> {
    return this.makeAxiosJSONRequest("GET", `${this.route}/scheduling-regulation/measurements`, {
      hdcId,
      from,
      to,
    });
  }

  fetchSchedulingParameters(): Promise<APIResult<SchedulingParameters[]>> {
    return this.makeAxiosJSONRequest("GET", `${this.route}/scheduling-regulation/parameters`);
  }

  fetchDistributionCircuits(): Promise<APIResult<HeatingDistributionCircuit[]>> {
    return this.makeAxiosJSONRequest("GET", `${this.route}/distribution-circuits`);
  }

  fetchSchedulingEvents(
    hdcId: number,
    from: number,
    to: number,
  ): Promise<APIResult<SchedulingEvent[]>> {
    return this.makeAxiosJSONRequest("GET", `${this.route}/scheduling-regulation/events`, {
      hdcId,
      from,
      to,
    });
  }

  fetchInstrumentStats(): Promise<APIResult<InstrumentStatsResponse>> {
    return this.makeAxiosJSONRequest("GET", `${this.route}/instrument/stats`);
  }

  fetchComfortMonitoring(): Promise<APIResult<ComfortMonitoring[]>> {
    return this.makeAxiosJSONRequest("GET", `${this.route}/comfort-monitoring`);
  }

  fetchComfortIndicators(from: number, to: number): Promise<APIResult<ComfortIndicator[]>> {
    return this.makeAxiosJSONRequest("GET", `${this.route}/comfort-indicators`, { from, to });
  }

  fetchLastLogbooks(): Promise<APIResult<SiteLogbook[]>> {
    return this.makeAxiosJSONRequest("GET", `${this.route}/last-logbooks`);
  }
}

export interface AlertService {
  acknowledgeProductionUnitAlert(alertId: number): Promise<APIResult<boolean>>;

  acknowledgeDistributionCircuitAlert(alertId: number): Promise<APIResult<boolean>>;

  acknowledgeInstrumentGroupAlert(alertId: number): Promise<APIResult<boolean>>;

  fetchHpuAlerts(mail: string): Promise<APIResult<HeatingProductionUnitAlert[]>>;

  saveHpuAlert(alert: HeatingProductionUnitAlert): Promise<APIResult<number>>;

  updateHpuAlert(alert: HeatingProductionUnitAlert): Promise<APIResult<number>>;

  deleteHpuAlert(alertId: number): Promise<APIResult<number>>;

  fetchAllBanners(): Promise<APIResult<AdminBanner[]>>;

  fetchActiveBanners(): Promise<APIResult<AdminBanner[]>>;

  saveBanner(banner: AdminBannerCreate): Promise<APIResult<AdminBanner>>;

  updateBanner(banner: AdminBanner): Promise<APIResult<AdminBanner>>;

  deleteBanner(bId: number): Promise<APIResult<{}>>;
}

export class AlertApiService extends APIService implements AlertService {
  private route = "/alerts";

  acknowledgeProductionUnitAlert(alertId: number): Promise<APIResult<boolean>> {
    return this.makeAxiosJSONRequest(
      "PUT",
      `${this.route}/production-units/${alertId}/acknowledge`,
    );
  }

  acknowledgeDistributionCircuitAlert(alertId: number): Promise<APIResult<boolean>> {
    return this.makeAxiosJSONRequest(
      "PUT",
      `${this.route}/distribution-circuits/${alertId}/acknowledge`,
    );
  }

  acknowledgeInstrumentGroupAlert(alertId: number): Promise<APIResult<boolean>> {
    return this.makeAxiosJSONRequest(
      "PUT",
      `${this.route}/instrument-groups/${alertId}/acknowledge`,
    );
  }

  fetchHpuAlerts(mail: string): Promise<APIResult<HeatingProductionUnitAlert[]>> {
    return this.makeAxiosJSONRequest("GET", `${this.route}/hpu`, { mail });
  }

  saveHpuAlert(alert: HeatingProductionUnitAlert): Promise<APIResult<number>> {
    return this.makeAxiosJSONRequest("POST", `${this.route}/hpu`, {}, alert);
  }

  updateHpuAlert(alert: HeatingProductionUnitAlert): Promise<APIResult<number>> {
    return this.makeAxiosJSONRequest("PUT", `${this.route}/hpu/${alert.id}`, {}, alert);
  }

  deleteHpuAlert(alertId: number): Promise<APIResult<number>> {
    return this.makeAxiosJSONRequest("DELETE", `${this.route}/hpu/${alertId}`);
  }

  fetchAllBanners(): Promise<APIResult<AdminBanner[]>> {
    return this.makeAxiosJSONRequest("GET", `${this.route}/banners`);
  }

  fetchActiveBanners(): Promise<APIResult<AdminBanner[]>> {
    return this.makeAxiosJSONRequest("GET", `${this.route}/active-banners`);
  }

  saveBanner(banner: AdminBannerCreate): Promise<APIResult<AdminBanner>> {
    return this.makeAxiosJSONRequest("POST", `${this.route}/banners`, {}, banner);
  }

  updateBanner(banner: AdminBanner): Promise<APIResult<AdminBanner>> {
    return this.makeAxiosJSONRequest("PUT", `${this.route}/banners/${banner.id}`, {}, banner);
  }

  deleteBanner(bId: number): Promise<APIResult<{}>> {
    return this.makeAxiosJSONRequest("DELETE", `${this.route}/banners/${bId}`);
  }

  sitesAlerts(): Promise<APIResult<SitesAlerts>> {
    return this.makeAxiosJSONRequest("GET", `${this.route}/all-default`);
  }
}
export class BuildingImageAPIService extends APIService implements BuildingService {
  private route = "/building-image";

  getImagesPositions(params: { imageIds: number[] }): Promise<APIResult<ImagePosition[]>> {
    return this.makeAxiosJSONRequest("GET", `${this.route}/position`, {
      imageId: params.imageIds,
    });
  }

  saveImagePositions(params: { ips: ImagePosition[] }): Promise<APIResult<void>> {
    return this.makeAxiosJSONRequest("PUT", `${this.route}/position`, {}, params.ips);
  }

  deleteImagePositions(params: { ips: ImagePosition[] }): Promise<APIResult<void>> {
    return this.makeAxiosJSONRequest("DELETE", `${this.route}/position`, {}, params.ips);
  }
}
