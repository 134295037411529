import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Button,
  Card,
  Checkbox,
  Form,
  Input,
  Modal,
  Select,
  Table,
  Typography,
  notification,
} from "antd";
import { FormInstance, useForm } from "antd/lib/form/Form";
import { ColumnType } from "antd/lib/table";
import * as React from "react";
import { colors, emTypeSource, formItemLayout } from "src/constants";
import { Permissions, hasPermission } from "src/lib/access-control";
import {
  EnergyMeter,
  EnergyMeterCategory,
  EnergyMeterMetadata,
  EnergyMeterRole,
  GRDFConsentDetails,
  GrdfDataType,
  PLC,
  Site,
} from "src/lib/api";
import { getPlcOptions } from "src/lib/data-points";
import {
  useSaveSiteEnergyMeter,
  useSiteEnergyMeters,
  useSitePlcs,
  useUpdateSiteEnergyMeter,
} from "src/lib/hooks/api";
import { useWindowWidth } from "src/lib/hooks/window-width";
import { withNotify } from "src/lib/notify";
import { showIf, sorter } from "src/lib/tableutils";
import ConsentDemandForm from "./ConsentDemandForm";

const unitOfMeasureOptions = [
  {
    value: "Mètres cubes",
    key: "cubic_meter",
  },
  {
    value: "Megawatt heure",
    key: "megawatt_hour",
  },
  {
    value: "Kilowatt heure",
    key: "kilowatt_hour",
  },
  {
    value: "Watt heure",
    key: "watt_hour",
  },
];

const roles = [
  {
    value: "ECS",
    key: EnergyMeterRole.Hdw,
  },
  {
    value: "Chauffage",
    key: EnergyMeterRole.Heating,
  },
  {
    value: "Chauffage + ECS",
    key: EnergyMeterRole.HeatingHdw,
  },
];

const sourceOptions = [
  {
    value: "CPCU",
    key: "cpcu",
  },
  {
    value: "GRDF",
    key: "grdf",
  },
  {
    value: "Automate",
    key: "plc",
  },
];

const grdfDataTypeOptions = [
  {
    value: "Données informatives",
    key: "informative",
  },
  {
    value: "Données publiées",
    key: "published",
  },
];

function getAddressLabel(type: string) {
  switch (type) {
    case "cpcu":
      return "Numéro de série (CPCU)";
    case "grdf":
      return "Numéro de série (GRDF)";
    case "plc":
      return "Adresse registre";
    default:
      return "Numéro de série";
  }
}

const EnergyMeterForm: React.FC<{ form: FormInstance<any>; plcs: PLC[] }> = ({ form, plcs }) => {
  const [hidePlcChoice, setHidePlcChoice] = React.useState(form.getFieldValue("source") !== "plc");
  const [hideGrdfChoice, setHideGrdfChoice] = React.useState(
    form.getFieldValue("source") !== "grdf",
  );
  const [addressLabel, setAddressLabel] = React.useState(
    getAddressLabel(form.getFieldValue("source")),
  );
  const [isReferenceMeter, setIsReferenceMeter] = React.useState(
    form.getFieldValue("category") === EnergyMeterCategory.Reference,
  );

  return (
    <Form name="energy-meter-form" {...formItemLayout} form={form}>
      <Form.Item name="id" hidden />
      <Form.Item name="metadata" hidden />
      <Form.Item name="name" label="Nom du compteur" rules={[{ required: true }]}>
        <Input placeholder={"Le Méridien - 75014 PARIS"} />
      </Form.Item>
      <Form.Item name="source" label="Type" rules={[{ required: true }]}>
        <Select
          placeholder={"Type de compteur"}
          onSelect={(value: string) => {
            form.setFieldsValue({ type: value });
            if (value === "plc") {
              setHidePlcChoice(false);
            } else {
              setHidePlcChoice(true);
            }

            if (value === "grdf") {
              setHideGrdfChoice(false);
            } else {
              setHideGrdfChoice(true);
            }
            setAddressLabel(getAddressLabel(value));
          }}
        >
          {sourceOptions.map((to: any) => {
            return <Select.Option value={to.key}>{to.value}</Select.Option>;
          })}
        </Select>
      </Form.Item>
      <Form.Item name="plcId" label="Automate associé" hidden={hidePlcChoice}>
        <Select placeholder={"Type de compteur"}>
          {getPlcOptions(plcs, false).map((p: any) => {
            return <Select.Option value={p.key}>{p.value}</Select.Option>;
          })}
        </Select>
      </Form.Item>
      <Form.Item name="grdfDataType" label="Type de donnée" hidden={hideGrdfChoice}>
        <Select placeholder={"Données publiées"}>
          {grdfDataTypeOptions.map((gdto: any) => {
            return <Select.Option value={gdto.key}>{gdto.value}</Select.Option>;
          })}
        </Select>
      </Form.Item>
      <Form.Item name="serialNumber" label={addressLabel} rules={[{ required: true }]}>
        <Input placeholder={"1905A1"} />
      </Form.Item>
      <Form.Item name="unitOfMeasure" label="Unité de mesure" rules={[{ required: true }]}>
        <Select placeholder={"Choisir une option"}>
          {unitOfMeasureOptions.map((umo: any) => {
            return <Select.Option value={umo.key}>{umo.value}</Select.Option>;
          })}
        </Select>
      </Form.Item>
      <Form.Item name="role" label="Rôle du compteur" rules={[{ required: true }]}>
        <Select placeholder={"Choisir une option"}>
          {roles.map((role: any) => {
            return <Select.Option value={role.key}>{role.value}</Select.Option>;
          })}
        </Select>
      </Form.Item>
      <Form.Item name="comments" label="Commentaire">
        <Input placeholder={"SDC Méridien"} />
      </Form.Item>
      <Form.Item name="category" label={"Comptage d'énergie"}>
        <Checkbox
          checked={isReferenceMeter}
          onChange={(e) => {
            setIsReferenceMeter(e.target.checked);
            form.setFieldsValue({
              category: e.target.checked
                ? EnergyMeterCategory.Reference
                : EnergyMeterCategory.Aucune,
            });
          }}
        >
          Définir ce compteur d'énergie comme référence
        </Checkbox>
        {isReferenceMeter && (
          <div style={{ marginLeft: 24 }}>
            <Typography.Text
              style={{ color: colors.red.dark, fontSize: 12, lineHeight: 0.5, fontStyle: "italic" }}
            >
              {`Attention ! En définissant ce compteur d'énergie comme référence, il sera automatiquement considéré dans la génération des rapports de consommation et l'affichage des données dans le dashboard.`}
            </Typography.Text>
          </div>
        )}
      </Form.Item>
    </Form>
  );
};

type EnergyMeterSource = "cpcu" | "grdf" | "plc" | "none";
type EnergyMeterFormFields = EnergyMeter & {
  source: EnergyMeterSource;
  plcId?: string;
  grdfDataType?: GrdfDataType;
};

const EnergyMetersConfigurationTab: React.FC<{ site: Site }> = ({ site }) => {
  const { data: energyMeters } = useSiteEnergyMeters(site.slug);
  const { data: plcs } = useSitePlcs(site.slug);

  const [initialValues, setInitialValues] = React.useState<GRDFConsentDetails>({} as any);
  const [selectedEnergyMeter, setSelectedEnergyMeter] = React.useState<EnergyMeter>();
  const { user } = useAuth0();
  const [form] = useForm();
  const { isWideScreen } = useWindowWidth();

  const [isModalVisible, setIsModalVisible] = React.useState<boolean>(false);
  const [isConsentModalVisible, setIsConsentModalVisible] = React.useState<boolean>(false);
  const [confirmLoading, setConfirmLoading] = React.useState<boolean>(false);

  const { mutateAsync: saveEnergyMeter } = useSaveSiteEnergyMeter(site.slug);
  const { mutateAsync: updateEnergyMeter } = useUpdateSiteEnergyMeter(site.slug);

  function onAddEnergyMeter() {
    form.setFieldsValue({ id: 0 });
    setIsModalVisible(true);
  }

  function computeMetadata(
    source: EnergyMeterSource,
    metadata: EnergyMeterMetadata,
    plcId?: string,
    grdfDataType?: "informative" | "published",
    cpcuSerialNumber?: string,
  ) {
    switch (source) {
      case "cpcu":
        return { ...metadata, pls: cpcuSerialNumber?.split("A")[0] };
      case "grdf":
        return { ...metadata, dataType: grdfDataType };
      case "plc":
        return { ...metadata, plcId: plcId?.toString() ?? "" };
      default:
        return { ...metadata };
    }
  }

  function computeType(source: EnergyMeterSource, plcId?: string) {
    switch (source) {
      case "cpcu":
        return "cpcu";
      case "grdf":
        return "grdf";
      case "plc":
        if (!plcId) {
          return "iq4";
        }
        const relevantPlc = plcs.find((plc: PLC) => plc.id === parseFloat(plcId));
        if (relevantPlc?.supplierId === 6) {
          return "distech";
        } else {
          return "iq4";
        }
      default:
        return "iq4";
    }
  }

  function onSaveEnergyMeter() {
    setConfirmLoading(true);
    form
      .validateFields()
      .then(async (energyMeterOutput: EnergyMeterFormFields) => {
        console.log(energyMeterOutput);
        if (energyMeterOutput.id === 0) {
          await withNotify(
            saveEnergyMeter({
              ...energyMeterOutput,
              siteId: site.id,
              type: computeType(energyMeterOutput.source, energyMeterOutput.plcId),
              metadata: computeMetadata(
                energyMeterOutput.source,
                energyMeterOutput.metadata,
                energyMeterOutput.plcId,
                energyMeterOutput.grdfDataType,
                energyMeterOutput.serialNumber,
              ),
              category: energyMeterOutput.category,
              role: energyMeterOutput.role,
            }),
            "L'automate a été créé avec succès",
            "Une erreur est survenue lors de la création du compteur d'énergie.",
          );
        } else {
          await withNotify(
            updateEnergyMeter({
              ...energyMeterOutput,
              siteId: site.id,
              id: energyMeterOutput.id,
              type: computeType(energyMeterOutput.source, energyMeterOutput.plcId),
              metadata: computeMetadata(
                energyMeterOutput.source,
                energyMeterOutput.metadata,
                energyMeterOutput.plcId,
                energyMeterOutput.grdfDataType,
                energyMeterOutput.serialNumber,
              ),
              category: energyMeterOutput.category,
              role: energyMeterOutput.role,
            }),
            "L'automate a été mis à jour avec succès",
            "Une erreur est survenue lors de la mise à jour du compteur d'énergie",
          );
        }
        resetForm();
      })
      .catch((err) => {
        console.log(err);
        notification.warning({
          message: `La modification ne peut pas être enregistrée car certains champs ne respectent pas les critères demandés.`,
        });
        setConfirmLoading(false);
      });
  }

  const formattedEms = energyMeters.map((em: EnergyMeter) => ({
    ...em,
    source: emTypeSource(em.type) as EnergyMeterSource,
    plcId: em.metadata?.plcId,
    grdfDataType: em.metadata?.dataType ?? "informative",
  }));

  function resetForm() {
    setIsModalVisible(false);
    setConfirmLoading(false);
    form.resetFields();
  }

  const columns: ColumnType<EnergyMeterFormFields>[] = [
    {
      title: "Nom du compteur",
      dataIndex: "name",
      key: "name",
      sorter: sorter("name", "string"),
    },
    {
      title: "Numéro de série",
      dataIndex: "serialNumber",
      key: "serialNumber",
    },
    {
      title: "Source",
      dataIndex: "source",
      key: "source",
      render: (source: EnergyMeterSource) => {
        switch (source) {
          case "cpcu":
            return "CPCU";
          case "grdf":
            return "GRDF";
          case "plc":
            return "Automate";
          case "none":
            return "Non renseignée";
          default:
            return "Non renseignée";
        }
      },
    },
    {
      title: "Commentaires",
      dataIndex: "comments",
      key: "comments",
    },
    ...showIf<EnergyMeterFormFields>(
      () => hasPermission(user, Permissions.HeatingInstallationsUpdate),
      {
        // see if we create specific permission for em update
        title: "Edition",
        key: "edition",
        responsive: ["lg" as const],
        width: 120,
        render: (record: EnergyMeterFormFields) => (
          <Button
            type="link"
            disabled={!hasPermission(user, Permissions.HeatingInstallationsUpdate)}
            icon={<EditOutlined />}
            onClick={() => {
              form.setFieldsValue(record);
              setIsModalVisible(true);
            }}
          >
            Modifier
          </Button>
        ),
      },
    ),
    ...showIf<EnergyMeterFormFields>(
      () => hasPermission(user, Permissions.HeatingInstallationsUpdate),
      {
        title: "Consentement client GRDF",
        key: "droitAccesGrdf",
        width: 250,
        render: (record: EnergyMeterFormFields) => {
          const isGrdfEnergyMeter = record.type === "grdf";

          return (
            <Button
              type="link"
              disabled={!isGrdfEnergyMeter}
              icon={<EditOutlined />}
              onClick={async () => {
                setSelectedEnergyMeter(energyMeters.find((em: EnergyMeter) => em.id === record.id));
                if (record.type === "grdf") {
                  setInitialValues({
                    pceId: record.metadata?.pceId ?? "",
                    postalCode: record.metadata?.postalCode ?? "",
                    ownerEmail: record.metadata?.ownerEmail ?? "",
                    ownerName: record.metadata?.ownerName ?? "",
                    socialReason: record.metadata?.socialReason ?? "",
                  });
                  setIsConsentModalVisible(true);
                }
              }}
            >
              Renseigner
            </Button>
          );
        },
      },
    ),

    // not implementing deletion yet
  ];

  async function onSaveGrdfConsent(em: EnergyMeter, grdfConsentDetails: GRDFConsentDetails) {
    await withNotify(
      updateEnergyMeter({
        ...em,
        metadata: {
          ...em.metadata,
          ...grdfConsentDetails,
        },
      }),
      "Les paramètres du PCE ont été enregistrés dans la fiche client vous pouvez les modiffier ultérieurement ici-même.",
      "Echec lors de l'enregistrement des données de consentement dans la fiche client.",
    );
  }

  return (
    <>
      <Card title="Automates présents sur site">
        <Table dataSource={formattedEms} columns={columns} pagination={false} />
        <div style={{ justifyContent: "center", display: "flex", margin: 20 }}>
          <Button icon={<PlusOutlined />} type="link" onClick={onAddEnergyMeter}>
            Ajouter un compteur
          </Button>
        </div>
      </Card>
      <Modal
        visible={isModalVisible}
        title={"Enregistrer un compteur d'énergie"}
        okText={"Enregistrer"}
        onCancel={resetForm}
        onOk={onSaveEnergyMeter}
        width={isWideScreen ? 900 : "50%"}
        destroyOnClose={true}
        confirmLoading={confirmLoading}
        okButtonProps={{ disabled: !hasPermission(user, Permissions.SitesUpdate) }}
      >
        <div style={{ marginBottom: 20 }}>
          <div style={{ marginBottom: 20 }}>
            <Typography.Text style={{ fontStyle: "italic", color: colors.blue.dark }}>
              Selon le type de compteur choisi, les informations demandées peuvent varier.
            </Typography.Text>
          </div>
          <EnergyMeterForm form={form} plcs={plcs} />
        </div>
      </Modal>
      <Modal
        visible={isConsentModalVisible}
        title={"Renseigner les informations de consentement"}
        onCancel={() => {
          setIsConsentModalVisible(false);
        }}
        forceRender={true}
        destroyOnClose={true}
        footer={[]}
      >
        <ConsentDemandForm
          initialValues={initialValues}
          energyMeter={selectedEnergyMeter}
          onSave={onSaveGrdfConsent}
        />
      </Modal>
    </>
  );
};

export default EnergyMetersConfigurationTab;
